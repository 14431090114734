import { environment } from './environment';

export default function getApiUrl(): string {
    switch (environment) {
        case 'dev':
            return 'https://devapi.grazeanalytics.com';
        case 'prod':
            return 'https://api.grazeanalytics.com';
    }

    return 'http://localhost:8080';
}
