import React, { createContext, DependencyList, useCallback, useContext, useState } from 'react';
import { blueberryClient, Surveys } from '../../helpers/blueberryClient';
import { useAsyncEffect } from '../../customHooks/useAsyncEffect';
import { sleep } from '../../helpers/sleep';
import { useParamsId } from '../../customHooks/useParamsId';

const useEditSurveysContextValue = () => {
    const [loaded, setLoaded] = useState(false);
    const [surveys, setSurveys] = useState<Surveys>({} as any);
    const loadSurveys = useCallback(async (delayPromise?: Promise<void>) => {
        const surveys = await blueberryClient.getSurveys();
        if (delayPromise) await delayPromise;
        setSurveys(surveys);
        setLoaded(true);
    }, []);

    const menuId = useParamsId('menuId');
    const surveyId = useParamsId('surveyId');

    return {
        loaded,
        loadSurveys,
        surveys,
        _setSurveys: setSurveys,
        params: {
            menuId,
            surveyId,
        },
    };
};

type SurveysContextValue = ReturnType<typeof useEditSurveysContextValue>;

const SurveysContext = createContext<SurveysContextValue | undefined>(undefined);

export const EditSurveysProvider = ({ children }) => {
    const value = useEditSurveysContextValue();
    useAsyncEffect(() => value.loadSurveys(), []);
    return <SurveysContext.Provider value={value}>{children}</SurveysContext.Provider>;
};

export const useEditSurveys = () => {
    const context = useContext(SurveysContext);
    if (context === undefined) {
        throw new Error('useSurveys must be used within a SurveysProvider');
    }
    return context;
};

const useLoadSurveys = () => useEditSurveys().loadSurveys;

export function useLoadSurveysCallback<T, TR>(promiseFn: (t: T) => Promise<TR>, deps: DependencyList) {
    const loadSurveys = useLoadSurveys();

    return useCallback(async (t: T) => {
        const sleeping = sleep(1000);
        const result = await promiseFn(t);
        await loadSurveys(sleeping);
        return result;
        // eslint-disable-next-line
    }, deps);
}
