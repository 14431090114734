import { ReviewToSession } from '../../model/reviews';
import { ContextText } from '../ContextText';
import React from 'react';
import { GrazeCard } from '../brand/GrazeCard';
import ReviewItem from '../ReviewItem';

interface Props {
    review: ReviewToSession;
}

export const SubjectCommentCard = ({ review }: Props) => {
    return (
        <GrazeCard>
            <ReviewItem review={review} doNotShowStarRating />
            <ContextText session={review.session} />
        </GrazeCard>
    );
};
