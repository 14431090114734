import { Input } from 'antd';
import styled from 'styled-components';
import React, { useEffect, useRef } from 'react';
import { InputProps } from 'antd/lib/input/Input';

export const GrowTextInputClass = styled.span<{ large: boolean }>`
    position: relative;
    input {
        position: absolute;
        border-radius: 999px;
        width: 100%;
        left: 0;
    }
    span {
        font-size: ${({ large }) => (large ? '18px' : '16px')};
        padding: 1.05em;
    }
`;

// Based on https://css-tricks.com/auto-growing-inputs-textareas/
export const GrowTextInput = ({ innerRef, ...props }: InputProps & { innerRef? }) => {
    const widthMachineRef = useRef<HTMLSpanElement>();
    const wrapRef = useRef<HTMLElement>();

    useEffect(() => {
        if (!wrapRef.current || !widthMachineRef.current) return;
        const input = wrapRef.current.querySelector('input');
        const widthMachine = widthMachineRef.current;
        const defaultWidthMachineContent = props.placeholder ?? 'Whatever';
        widthMachine.innerHTML = defaultWidthMachineContent;
        const minClientWidth = input.clientWidth;
        const updateWidthMachine = () => {
            widthMachine.innerHTML = input.value;
            if (input.clientWidth < minClientWidth) widthMachine.innerHTML = defaultWidthMachineContent;
        };
        input.addEventListener('keyup', updateWidthMachine);
        input.addEventListener('keydown', updateWidthMachine);
        input.addEventListener('keypress', updateWidthMachine);
    }, [props.placeholder]);

    return (
        <GrowTextInputClass ref={wrapRef} large={props.size === 'large'}>
            <span aria-hidden="true" ref={widthMachineRef}>
                {props.placeholder}
            </span>
            <Input {...props} ref={innerRef} />
        </GrowTextInputClass>
    );
};
