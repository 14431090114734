import { FeedbackMetricProps, FeedbackMetrics } from './FeedbackMetrics';
import React from 'react';
import { SessionResponse } from '../../helpers/blueberryClient';
import { HeadingWithOverviewTimeRangeSelect } from './HeadingWithOverviewTimeRangeSelect';
import { useSessionsInOverviewTimeRange } from '../../customHooks/useSessionsInOverviewTimeRange';
import { useStoreState } from '../../model';
import { RatingsBreakdown } from './RatingsBreakdown';

const useMetrics = (sessionsForLastDays: SessionResponse[]) => {
    const loading = !useStoreState((state) => state.sessionModel.sessionsFullyUpdated);

    const feedbackMetrics: FeedbackMetricProps = {
        reviewersCount: 0,
        starRatingsCount: 0,
        commentsCount: 0,
        emailsCount: 0,
    };

    const reviewsForLastDays = sessionsForLastDays.flatMap((x) => x.Reviews);

    sessionsForLastDays.forEach((x) => {
        feedbackMetrics.reviewersCount++;
        if (x.Reviewer?.contactable && x.Reviewer?.email) feedbackMetrics.emailsCount++;
    });

    reviewsForLastDays.forEach((x) => {
        if (x.comment && x.comment.trim().length > 0) feedbackMetrics.commentsCount++;
        if (!x.starRating || x.starRating < 1) return;
        feedbackMetrics.starRatingsCount++;
    });

    return {
        feedbackMetrics,
        loading,
        reviewsForLastDays,
    };
};

export const Overview = () => {
    const sessionsForRange = useSessionsInOverviewTimeRange();
    const { feedbackMetrics, reviewsForLastDays, loading } = useMetrics(sessionsForRange);

    return (
        <>
            <HeadingWithOverviewTimeRangeSelect>Overview</HeadingWithOverviewTimeRangeSelect>
            <FeedbackMetrics {...feedbackMetrics} />
            <RatingsBreakdown
                reviews={reviewsForLastDays}
                loading={loading}
                onClicks={{ 1: '/star/1', 2: '/star/2', 3: '/star/3', 4: '/star/4', 5: '/star/5' }}
            />
        </>
    );
};
