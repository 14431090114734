import { Action, action, Computed, computed, persist, Thunk, thunk } from 'easy-peasy';
import jwt from 'jsonwebtoken';
import { blueberryClient } from '../helpers/blueberryClient';

export interface JwtContents {
    restaurantId: number;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    lastLogin: string;
    iat: number; // JWT gives these by default. Currently unused
    exp: number; // JWT gives these by default. Currently unused
}

export interface UserModel {
    jwtContents: JwtContents;
    jwtToken: string;
    isLoggedIn: boolean;
    setJwtContents: Action<UserModel, string>;

    restaurants: Restaurant[];
    _setRestaurants: Action<UserModel, Restaurant[]>;
    currentRestaurant: Computed<UserModel, Restaurant>;
    hydrateRestaurants: Thunk<UserModel>;
}

const userModel: UserModel = persist(
    {
        jwtContents: null,
        jwtToken: null,
        isLoggedIn: false,
        setJwtContents: action((state, token) => {
            state.jwtToken = token;
            state.jwtContents = jwt.decode(token) as JwtContents;
            state.isLoggedIn = true;
        }),

        restaurants: [],
        _setRestaurants: action((state, restaurants) => {
            state.restaurants = restaurants;
        }),
        currentRestaurant: computed(
            [(self) => self.restaurants, (self) => self.jwtContents?.restaurantId],
            (restaurants, currentRestaurantId) => {
                const restaurant = restaurants.find((x) => x.id === currentRestaurantId);
                if (!restaurant) return { id: -1, title: `?Unknown Restaurant?` };
                return restaurant;
            }
        ),
        hydrateRestaurants: thunk(async (actions) => {
            const restaurants = await blueberryClient.getRestaurants();
            actions._setRestaurants(restaurants);
        }),
    },
    { storage: 'localStorage' }
);

export interface Restaurant {
    id: number;
    title: string;
}

export default userModel;
