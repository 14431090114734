import React, { useRef } from 'react';
import { VerticalCenteredGrazeSpinner } from './brand/VerticalCenteredGrazeSpinner';
import { NotFound } from './NotFound';
import { useKetchupMustardSea } from '../customHooks/useKetchupMustardSea';
import { GrazeLayout } from './brand/GrazeLayout';
import { SessionCard } from './card/SessionCard';
import { GrazePagination } from './brand/GrazePagination';
import { useStoreState } from '../model';
import { useParams } from 'react-router-dom';
import { useHydrateSessions } from '../customHooks/useHydrateSessions';
import styled from 'styled-components';
import { AverageRating } from './AverageRating';
import { countComments, getRatings } from '../model/reviews';

const useEmail = () => {
    const { email } = useParams() as any;
    return email as string;
};

const H1 = styled.h1`
    margin-bottom: 0.1em;
`;

export const GuestRouter = () => {
    const email = useEmail();

    useKetchupMustardSea();
    const guests = useStoreState((x) => x.guestsModel.guests);
    const { sessionsHydrated } = useHydrateSessions();
    const topmostElement = useRef<HTMLElement>(null);

    if (!sessionsHydrated) return <VerticalCenteredGrazeSpinner />;

    const guest = guests.find((x) => x.email.toLowerCase().trim() === email.toLowerCase().trim());

    if (!guest) return <NotFound />;

    const { sessions } = guest;
    const reviews = sessions.flatMap((x) => x.Reviews);
    const { averageRating, ratingsCount } = getRatings(reviews);
    const commentsCount = countComments(reviews);

    return (
        <GrazeLayout>
            <H1 ref={topmostElement as any}>{email}</H1>
            <GuestPageStats {...{ averageRating, ratingsCount, commentsCount }} />
            <GrazePagination items={sessions} defaultPage={1} itemsPerPage={25} scrollTo={topmostElement}>
                {({ items }) => (
                    <>
                        {items.map((session) => (
                            <SessionCard key={session.sessionUUID} session={session} />
                        ))}
                    </>
                )}
            </GrazePagination>
        </GrazeLayout>
    );
};

const H2 = styled.h2`
    font-weight: 400;
`;

const GuestPageStats = ({ averageRating, ratingsCount, commentsCount }) => (
    <H2>
        <AverageRating value={averageRating} /> • {ratingsCount} {ratingsCount === 1 ? 'rating' : 'ratings'} •{' '}
        {commentsCount} {commentsCount === 1 ? 'comment' : 'comments'}
    </H2>
);
