import moment, { Moment } from 'moment';
import getApiUrl from './getApiUrl';
import axiosFactory, { AxiosError, AxiosRequestConfig } from 'axios';
import { UserModel } from '../model/user';
import { State } from 'easy-peasy';
import { ApricotStore } from '../store';
import { MenuItem } from '../model/menu';
import { Prompt } from '../model/prompts';
import { Review } from '../model/reviews';
import { wait } from './wait';

export interface SessionResponse {
    sessionUUID: string;
    updatedAt: Moment;
    OrderId: number | null;
    sessionCompleted: true;
    Survey: Survey;
    Reviewer: Reviewer | null;
    Reviews: Review[];
}

interface Reviewer {
    contactable: boolean;
    email: string;
}

export interface Survey {
    id: number;
    name: string;
}

const makeBlueberryClientConfig = (userModel?: State<UserModel>) => {
    return {
        restaurantId: userModel?.jwtContents?.restaurantId ?? undefined,
        jwtToken: userModel?.jwtToken ?? undefined,
    };
};

const makeAxios = (config: Partial<AxiosRequestConfig>) => axiosFactory.create({ baseURL: getApiUrl(), ...config });

let config = makeBlueberryClientConfig();

const makeBlueberryClient = ({ restaurantId, jwtToken }: ReturnType<typeof makeBlueberryClientConfig>) => {
    const axios = makeAxios({ headers: { Authorization: `Bearer ${jwtToken}` } });
    return {
        async getRecentCompletedSessions(limitResults?: number) {
            const res = await axios('/session', {
                params: { RestaurantId: config.restaurantId, limitResults, sessionCompleted: true },
            });
            res.data.forEach((session) => {
                session.updatedAt = moment(session.updatedAt);
            });
            return res.data as SessionResponse[];
        },
        async getMenuItems() {
            return (await axios(`/menuitems`, { params: { restaurantId } })).data as MenuItem[];
        },
        async getPrompts() {
            return (await axios(`/prompts`, { params: { restaurantId } })).data as Prompt[];
        },
        async hideMenuItem(menuItemId: number) {
            return await axios.put(`/menuItem/${menuItemId}`, { active: false });
        },
        async hideMenuSection(menuSectionId: number) {
            return await axios.put(`/menuSection/${menuSectionId}`, { active: false });
        },
        async hidePrompt(promptId: number) {
            return await axios.put(`/prompt/${promptId}`, { active: false });
        },
        async createMenuItem({ title, menuSectionId }: CreateMenuItemArgs) {
            return await axios.post(`/menuItem`, { title, menuSectionId });
        },
        async createMenuSection({ title, menuId }) {
            return await axios.post(`/menuSection`, { title, menuId });
        },
        async createPrompt({ surveyId, title }) {
            return await axios.post(`/prompt`, {
                title,
                surveyId,
                promptType: 'general',
                active: true,
            });
        },
        async createSubscription() {
            return (await axios.post(`/restaurant/${restaurantId}/subscription`)).data as { stripeSessionId: string };
        },
        async getSubscription() {
            const subscription = (await axios(`/restaurant/${restaurantId}/subscription`)).data;
            subscription.goodThrough = moment(subscription.goodThrough);
            return subscription as Subscription;
        },
        async getSubscriptionPortalSession() {
            return (await axios.put(`/restaurant/${restaurantId}/subscription`)).data as {
                stripeBillingPortalSessionUrl: string;
            };
        },
        async getSurveys() {
            return (await axios(`/restaurant/${restaurantId}/surveys`)).data as Surveys;
        },
        async updateMenuSectionOrder({ sortedIds }) {
            return await axios.put(`/menuSections/order`, { sortedIds });
        },
        async createSurvey({ name }) {
            return await axios.post(`/survey`, {
                name,
            });
        },
        async createMenuOnSurvey({ title, surveyId }) {
            return (
                await axios.post(`/survey/${surveyId}/menu`, {
                    title,
                })
            ).data as { id };
        },
        async createIncentiveOnSurvey({ surveyId, statement, promoCode, expirationDays }) {
            return await axios.post(`/survey/${surveyId}/incentive`, {
                statement,
                promoCode,
                expirationDays,
            });
        },
        async removeIncentiveFromSurvey({ surveyId, incentiveId }) {
            return await axios.delete(`/survey/${surveyId}/incentive/${incentiveId}`);
        },
        async updateIncentive({ incentiveId, statement, promoCode, expirationDays }) {
            return await axios.put(`/incentive/${incentiveId}`, {
                statement,
                promoCode,
                expirationDays,
            });
        },
        updateSurvey: ({ surveyId, name }) =>
            axios.put(`/survey/${surveyId}`, {
                name,
            }),
        updateMenu: ({ menuId, title }) =>
            axios.put(`/menu/${menuId}`, {
                title,
            }),
        updateMenuSection({ menuSectionId, title }) {
            return axios.put(`/menuSection/${menuSectionId}`, { title });
        },
        async getQuestionGallery({ surveyId }) {
            return (await axios.get(`/survey/${surveyId}/question-gallery`)).data;
        },
        createPromptFromPrefab({ surveyId, prefabId }) {
            return axios.post(`/survey/${surveyId}/prompt/from-prefab/${prefabId}`);
        },
        createMultipleChoicePrompt({ surveyId, title, choices }) {
            return axios.post(`/survey/${surveyId}/prompt/multiple-choice`, { title, choices });
        },
        async getRestaurants() {
            return (await axios.get(`/user/restaurants`)).data;
        },
        async getJwtForRestaurant(restaurantId: number) {
            return (await axios.get(`/user/restaurant/${restaurantId}/jwt`)).data.token;
        },
        async getEmailPreferences() {
            return (await axios.get(`/user/email-preferences`)).data;
        },
        async updateEmailPreferences(preferences) {
            await axios.put(`/user/email-preferences`, preferences);
        },
        async getTeamMembers() {
            return (await axios.get(`/user/team`)).data as { firstName; lastName; email }[];
        },
        async joinRestaurant(restaurantUuid: string) {
            return (await axios.post(`/restaurant/${restaurantUuid}/join`)).data as { token };
        },
        async sendInviteEmail(email: string) {
            await axios.post(`/invite`, { email });
        },
        async changeMenuItemSpelling(id: number, newTitle: string) {
            await axios.put(`/menuItem/${id}`, { title: newTitle });
        },
    };
};

export interface Subscription {
    goodThrough: Moment;
    hasStripeCustomer: boolean;
    status: 'NeverPaid' | 'PaymentFailed' | 'PaymentDue' | 'Paid';
}

export const blueberryClient = new Proxy(
    {},
    {
        get(obj, prop) {
            return async function (...args) {
                await wait(() => config.restaurantId);
                const client = makeBlueberryClient(config);
                return await client[prop](...args);
            };
        },
    }
) as ReturnType<typeof makeBlueberryClient>;

export const unauthorizedBlueberryClient = {
    async signUp(data, restaurantUuid?: string) {
        const axios = makeAxios({});
        try {
            const url = restaurantUuid ? `restaurant/${restaurantUuid}/sign-up` : 'sign-up';
            return (await axios.post(url, data)).data as { token };
        } catch (e) {
            const ae = e as AxiosError;
            if (ae.response.status === 400) return ae.response.data as { errors: string[] };
            return { errors: ['An unknown error occurred. Please try again later.'] };
        }
    },
    async getRestaurant(restaurantUuid: string) {
        const axios = makeAxios({});
        return (await axios.get(`/restaurant/${restaurantUuid}`)).data as { title: string };
    },
};

export interface Surveys {
    menus: { id; title }[];
    menuSections: { id: number; title: string; active: boolean; menuId: number }[];
    menuItems: { id: number; title: string; menuSectionId: number; active: boolean }[];
    prompts: SurveyPrompt[];
    surveys: { id; uuid; name; menuIds: number[]; incentive: { id: number; statement; promoCode; expirationDays } }[];
}

export interface SurveyPrompt {
    id;
    title;
    active;
    surveyId;
    flavor;
    flavorData;
}

export interface CreateMenuItemArgs {
    title: string;
    menuSectionId: number;
}

export function startListeningToUserModel(store: ApricotStore) {
    store.subscribe(() => {
        config = makeBlueberryClientConfig(store.getState().userModel);
    });
}
