import data from './data.json';

export type EmojiName = keyof typeof data;

export const getEmojiUrl = (name: EmojiName) => {
    const code = data[name];

    if (!code) return undefined;

    return `https://emoji.grazeanalytics.com/${code}.png`;
};
