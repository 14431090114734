import React, { useCallback, useEffect, useState } from 'react';
import { useKetchupMustardSea } from '../../customHooks/useKetchupMustardSea';
import { GrazeLayout } from '../brand/GrazeLayout';
import { Input } from 'antd';
import styled from 'styled-components';
import { GreenButton } from '../brand/GreenButton';
import { GrazeCard } from '../brand/GrazeCard';
import { allValuesTruthy } from '../../helpers/allValuesTruthy';
import { blueberryClient, unauthorizedBlueberryClient } from '../../helpers/blueberryClient';
import { useStoreActions, useStoreState } from '../../model';
import { Link, useHistory, useParams } from 'react-router-dom';
import { VerticalCenteredGrazeSpinner } from '../brand/VerticalCenteredGrazeSpinner';
import { useAsyncEffect } from '../../customHooks/useAsyncEffect';
import { sleep } from '../../helpers/sleep';
import { useQuery } from '../../customHooks/useQuery';

function useFormState<T extends object>(defaultValue: T) {
    const [formState, setFormState] = useState(defaultValue);
    const setValue = useCallback(
        (k: keyof T) => (value) => {
            setFormState({ ...formState, [k]: value });
        },
        [formState, setFormState]
    );
    return { setValue, formState };
}

const useSignUp = (restaurantUuid?: string) => {
    const setJWTContents = useStoreActions((actions) => actions.userModel.setJwtContents);
    const history = useHistory();
    const [signingUp, setSigningUp] = useState(false);
    const [errors, setErrors] = useState([]);

    const signUp = useCallback(
        async (formState) => {
            setSigningUp(true);
            const response = await unauthorizedBlueberryClient.signUp(formState, restaurantUuid);
            if ('errors' in response) {
                setErrors(response.errors);
                setSigningUp(false);
                return;
            }
            setJWTContents(response.token);
            history.push(restaurantUuid ? '/dashboard' : '/surveys');
        },
        [setJWTContents, history]
    );

    return { signingUp, signUp, errors };
};

export const SignUpPage = () => {
    useKetchupMustardSea('high');
    return (
        <Layout>
            <h1>Sign up</h1>
            <SignUpForm />
        </Layout>
    );
};

export const JoinPage = () => {
    useKetchupMustardSea('high');
    const restaurantUuid = useParams()['restaurantUuid'];
    const defaultEmailAddress = useQuery().get('email') ?? '';
    const [restaurant, setRestaurant] = useState(undefined as { title } | undefined);
    const isLoggedIn = useStoreState((state) => state.userModel.isLoggedIn);
    useAsyncEffect(async () => {
        const s = sleep(250);
        const restaurant = await unauthorizedBlueberryClient.getRestaurant(restaurantUuid);
        await s;
        setRestaurant(restaurant);
    }, []);
    if (!restaurant) return <VerticalCenteredGrazeSpinner />;
    return (
        <Layout>
            <h1>Join {restaurant.title}</h1>
            {isLoggedIn && <JoinAlreadyLoggedIn restaurantUuid={restaurantUuid} restaurantTitle={restaurant.title} />}
            {!isLoggedIn && (
                <SignUpForm {...{ restaurantUuid, defaultEmailAddress }}>
                    <P>
                        Already have an account?{' '}
                        <Link to={{ pathname: '/login', search: `?onlogin=/join/${restaurantUuid}` }}>
                            Please login.
                        </Link>
                    </P>
                </SignUpForm>
            )}
        </Layout>
    );
};

const JoinAlreadyLoggedIn = ({ restaurantUuid, restaurantTitle }) => {
    const setJWTContents = useStoreActions((actions) => actions.userModel.setJwtContents);
    const history = useHistory();

    const firstName = useStoreState((state) => state.userModel.jwtContents.firstName);
    const [loading, setLoading] = useState(false);

    const doJoin = async () => {
        setLoading(true);
        const response = await blueberryClient.joinRestaurant(restaurantUuid);
        setJWTContents(response.token);
        history.push('/dashboard');
    };

    return (
        <JoinLoggedInCard>
            <p>
                Hi <strong>{firstName}</strong>, click below to join <strong>{restaurantTitle}</strong>.
            </p>
            <GreenButton size="large" loading={loading} onClick={doJoin}>
                Join
            </GreenButton>
        </JoinLoggedInCard>
    );
};

const P = styled.p`
    text-align: center;
    margin-top: 1em;
    a:hover {
        text-decoration: underline;
    }
`;

const SignUpForm = ({
    defaultEmailAddress = '',
    restaurantUuid = undefined as string | undefined,
    children = undefined,
}) => {
    const { setValue, formState } = useFormState({
        restaurantName: '',
        fullName: '',
        emailAddress: defaultEmailAddress,
        userName: '',
        password: '',
        confirmPassword: '',
    });
    if (restaurantUuid) delete formState.restaurantName;
    const [disabled, setDisabled] = useState(true);
    useEffect(() => {
        setDisabled(!allValuesTruthy(formState));
    }, [formState, setDisabled]);
    const { signUp, signingUp, errors } = useSignUp(restaurantUuid);

    return (
        <form>
            <Card>
                {!restaurantUuid && (
                    <TextInput
                        label="Restaurant name"
                        autoComplete="organization"
                        setValue={setValue('restaurantName')}
                    />
                )}
                <TextInput label="Your full name" autoComplete="name" setValue={setValue('fullName')} />
                <TextInput
                    label="Email address"
                    autoComplete="email"
                    type="email"
                    defaultValue={defaultEmailAddress}
                    setValue={setValue('emailAddress')}
                />
                <Spacer />
                <TextInput label="Username" autoComplete="username" setValue={setValue('userName')} />
                <Spacer />
                <TextInput
                    label="Password"
                    autoComplete="new-password"
                    type="password"
                    setValue={setValue('password')}
                />
                <TextInput
                    label="Confirm password"
                    autoComplete="new-password"
                    type="password"
                    setValue={setValue('confirmPassword')}
                />
                <Note>Choose a password at least 6 characters in length.</Note>
                <GreenButton size="large" disabled={disabled} loading={signingUp} onClick={() => signUp(formState)}>
                    Sign up
                </GreenButton>
                {errors.map((x) => (
                    <Error key={x}>{x}</Error>
                ))}
                {children}
            </Card>
        </form>
    );
};

const Error = styled.p`
    font-size: 14px;
    color: #e64654;
    margin-bottom: 8px;
`;

const Note = styled.p`
    font-size: 14px;
    color: gray;
`;

const Spacer = styled.div`
    border-bottom: solid 1px #e8e8e8;
    margin: 12px 0;
`;

const Layout = styled(GrazeLayout)`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 600px;
    @media only screen and (min-width: 632px) {
        justify-content: center;
        padding-top: 16px;
    }
    @media only screen and (max-width: 631px) {
        margin: 16px;
    }
`;

const Card = styled(GrazeCard)`
    display: flex;
    flex-direction: column;
    input {
        margin-bottom: 8px;
    }
    button {
        align-self: center;
        margin-top: 16px;
    }
    > *:last-child {
        margin-bottom: 0;
    }
`;

const JoinLoggedInCard = styled(GrazeCard)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    strong {
        font-weight: 500;
    }
    p {
        font-size: 112%;
    }
    min-height: 140px;
`;

const Label = styled.label`
    display: block;
`;

const TextInput = ({ label, setValue, ...props }) => {
    return (
        <>
            <Label htmlFor={label}>{label}</Label>
            <Input name={label} size="large" onChange={(x) => setValue(x.target.value)} {...props} />
        </>
    );
};
