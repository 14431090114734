import styled from 'styled-components';
import React from 'react';
import { SessionResponse } from '../helpers/blueberryClient';

const Div = styled.div`
    margin-top: 0.75em;
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.5);
`;

export const ContextText = ({ session }: { session: SessionResponse }) => {
    const { updatedAt } = session;
    const { name } = session.Survey;
    return <Div>{`${name} • ${updatedAt.format('ddd, MMM D')} • ${updatedAt.format('ha')}`}</Div>;
};
