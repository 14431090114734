import styled from 'styled-components';
import React from 'react';

// Adapted from https://codepen.io/FelixRilling/pen/vLRNvb

const Div = styled.div`
    width: 400px;
    height: 650px;
    border: 40px solid #ddd;
    border-width: 55px 7px;
    border-radius: 40px;
    margin: 50px auto;
    box-shadow: 0 3px 0 #bbb, 0 4px 0 #bbb, 0 5px 0 #bbb, 0 7px 0 #bbb, 0 10px 20px #666;
`;

const Iframe = styled.iframe`
    background: #fff;
    border: 0;
    width: 100%;
    height: 100%;
`;

export const PhoneIframe = ({ src }) => (
    <Div>
        <Iframe src={src} />
    </Div>
);
