import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Div = styled.div`
    display: flex;
    > :last-child {
        margin-left: 0.4em;
    }
`;

export const TextInputWithSubmit = ({ placeholder, onSubmit, ...rest }) => {
    const [text, setText] = useState('');
    const onSubmitImpl = () => {
        if (text) onSubmit(text);
    };

    return (
        <Div className={rest.className}>
            <Input
                placeholder={placeholder}
                onChange={(x) => setText(x.target.value)}
                onPressEnter={onSubmitImpl}
                ref={rest.inputRef}
            />
            <Button type="primary" onClick={onSubmitImpl} disabled={!text || rest.disabled === true}>
                <CheckOutlined />
            </Button>
        </Div>
    );
};
