import { ReviewToSession } from '../model/reviews';
import { now } from '../helpers/now';
import { useStoreState } from '../model';
import { useOverviewTimeRange } from './useOverviewTimeRange';

const getReviewsForLastDays = (reviews: ReviewToSession[], days: number) =>
    reviews.filter((x) => x.session.updatedAt.diff(now.date, 'days') >= -days);

export const useReviewsInOverviewTimeRange = () => {
    const reviews = useStoreState((state) => state.reviewsModel.reviews);
    const [range] = useOverviewTimeRange();
    return range === '30-days' ? getReviewsForLastDays(reviews, 30) : reviews;
};
