import React from 'react';

import ReviewItem from '../ReviewItem';
import { SessionResponse } from '../../helpers/blueberryClient';
import { FeedbackCard } from './FeedbackCard';
import { ContextText } from '../ContextText';
import styled from 'styled-components';
import { getAverageRating } from '../../model/reviews';
import { sortBy } from 'lodash-es';

interface Props {
    session: SessionResponse;
}

const EmailDiv = styled.div`
    font-weight: 500;
    margin-bottom: 0.5em;
`;

const Card = styled(FeedbackCard)`
    margin-bottom: 10px;
`;

export const SessionCard = ({ session }: Props): React.ReactElement => {
    const { Reviews, Reviewer } = session;
    const sortedReviews: typeof Reviews = sortBy(Reviews as any, (x) => [!!x.MenuItem, x.Prompt?.id || x.MenuItem?.id]);
    const averageRating = getAverageRating(sortedReviews);

    return (
        <Card rating={averageRating}>
            {Reviewer?.contactable && <EmailDiv>{Reviewer.email}</EmailDiv>}
            <div>
                {sortedReviews.map((rev) => (
                    <ReviewItem key={session.sessionUUID + '_' + rev.id} review={rev} />
                ))}
            </div>
            <ContextText session={session} />
        </Card>
    );
};
