import { Tag } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { EditableText } from '../brand/EditableText';

const StyledTag = styled(Tag)`
    font-weight: 400;
`;

const H1 = styled.h1`
    margin-bottom: 6px;
`;

export const HeaderWithTag = ({ title, tag, color, updateTitle, editable }) => (
    <H1>
        <StyledTag color={color}>{tag}</StyledTag>
        {editable && <EditableText value={title} setValue={updateTitle} />}
        {!editable && <div>{title}</div>}
    </H1>
);
