import { Action, action, computed, Computed, State, Thunk } from 'easy-peasy';
import { blueberryClient, Survey } from '../helpers/blueberryClient';
import { NotifyNetworkError } from '../helpers/NotifyNetworkError';
import { debouncedThunk } from '../helpers/debouncedThunk';
import { isPromptReview, PromptReviewToSession } from './reviews';
import { ModelInterface } from './index';
import { groupBy, mapValues, uniq } from 'lodash-es';

export enum PromptType {
    service = 'service',
    atmosphere = 'atmosphere',
    general = 'general',
}

export interface Prompt {
    id: number;
    title: string;
    promptType: PromptType;
    flavor: string;
    Survey: Survey;
}

export interface PromptExt extends Prompt {
    reviews: PromptReviewToSession[];
    surveyNames: string[];
}

export interface PromptsModel {
    prompts: Computed<PromptsModel, PromptExt[]>;
    _prompts: Prompt[];
    _setPrompts: Action<PromptsModel, Prompt[]>;
    promptsAdded: boolean;
    hydrateAllPrompts: Thunk<PromptsModel>;
}

const promptsModel: PromptsModel = {
    _prompts: [],
    prompts: computed(
        [
            (promptsModel) => promptsModel._prompts,
            (_, storeState: State<ModelInterface>) => storeState.reviewsModel.reviews,
        ],
        (prompts, reviews) => {
            const promptReviews = reviews.filter(isPromptReview) as PromptReviewToSession[];
            const promptReviewsByTitle = groupBy(promptReviews, (x) => x.Prompt.title);
            const surveysByTitle = mapValues(groupBy(prompts, 'title'), (x) => uniq(x.map((x) => x.Survey.name)));
            return prompts.map((x) => ({
                ...x,
                reviews: promptReviewsByTitle[x.title] ?? [],
                surveyNames: surveysByTitle[x.title] ?? [],
            }));
        }
    ),
    promptsAdded: false,
    _setPrompts: action((state, payload) => {
        state._prompts = payload;
        state.promptsAdded = true;
    }),
    hydrateAllPrompts: debouncedThunk(async (actions) => {
        try {
            const prompts = await blueberryClient.getPrompts();
            actions._setPrompts(prompts);
        } catch (e) {
            NotifyNetworkError('Error fetching prompts.');
            console.error('prompts hydration error:', e);
        }
    }),
};

export default promptsModel;
