import React from 'react';
import { Layout } from 'antd';

import styled, { css } from 'styled-components';
import { useSubscription } from '../customHooks/useSubscription';
import { Paywall } from './home/Paywall';
import { GrazeNavigation } from './brand/GrazeNavigation';
import { RootLayout } from './RootLayout';

const { Content } = Layout;

export interface WrapperProps {
    collapsed: boolean;
    location: { pathname: string };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledContent = styled(({ paywall, ...rest }) => <Content {...rest} />)<{ paywall: boolean }>`
    ${({ paywall }) =>
        paywall &&
        css`
            filter: blur(2px);
            pointer-events: none;
            user-select: none;
        `}
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WrapHOC =
    (WrappedComponent: any, paywall: 'Paywall' | 'NoPaywall' = 'Paywall'): React.FunctionComponent<WrapperProps> =>
    (props) => {
        const subscription = useSubscription();
        const showPaywall = paywall === 'Paywall' && subscription && subscription.status !== 'Paid';
        return (
            <RootLayout>
                <GrazeNavigation />
                {showPaywall && <Paywall />}
                <StyledContent paywall={showPaywall}>
                    <WrappedComponent {...props} />
                </StyledContent>
            </RootLayout>
        );
    };

export default WrapHOC;
