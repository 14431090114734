import React from 'react';
import data from './data.json';
import { HTMLAttributes } from 'react';
import { getEmojiUrl } from './getEmojiUrl';
import styled from 'styled-components';

type Name = keyof typeof data;

interface Props {
    name: Name;
    width?: number;
    innerRef?: React.Ref<HTMLImageElement>;
}

const Img = styled.img`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
`;

export const Emoji = ({ name, innerRef, ...props }: Props & HTMLAttributes<HTMLImageElement>) => {
    const url = getEmojiUrl(name);

    return <Img src={url} alt={name} aria-label={name} ref={innerRef} {...props} />;
};
