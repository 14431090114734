import React, { HTMLAttributes } from 'react';

import RatingTag from '../RatingTag';
import { GrazeCard } from '../brand/GrazeCard';
import styled from 'styled-components';

const LayoutCard = styled(GrazeCard)`
    display: flex;
    & > *:nth-child(2) {
        flex-grow: 1;
        flex-basis: 0;
    }
`;

interface Props {
    rating: number;
}

export const FeedbackCard = (props: Props & HTMLAttributes<{}>): React.ReactElement => {
    const { rating, children } = props;

    return (
        <LayoutCard data-testid={'patronFeedbackCard'} {...props}>
            <div>
                <RatingTag rating={rating} />
            </div>
            <div>{children}</div>
        </LayoutCard>
    );
};
