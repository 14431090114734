import { useEffect } from 'react';
import { viewport } from '../helpers/viewport';
import { lerp } from '../helpers/lerp';
import { device } from '../helpers/device';

let scrollUnit = 0;

const seed = Math.random() * 10000;

const time = {
    get now() {
        return seed + scrollUnit * 10;
    },
};

const makeWiggle = (ctx: CanvasRenderingContext2D, flavor: 'ketchup' | 'mustard', offset: number) => {
    const flavorConstants = {
        ketchup: [offset + 0.075, 0.3, 16, 0.02, 0.2, 0.07, 0.6],
        mustard: [offset, 0.27, 12, 0.021, 0.175, 0.09, 1],
    };
    const c = flavorConstants[flavor];
    return (x: number) => {
        const y1 = c[0] + Math.sin(time.now * c[1] - x * c[2]) * c[3];
        const f = Math.pow(Math.sin(x + y1 + time.now * c[4]), 2) * c[5] + 1;
        const y = y1 * f - scrollUnit * c[6];
        if (x === 0) ctx.moveTo(x, y);
        else ctx.lineTo(x, y);
    };
};

const makeDrawSea = (ctx: CanvasRenderingContext2D) => {
    return (fillStyle: typeof CanvasRenderingContext2D.prototype.fillStyle, wiggle: ReturnType<typeof makeWiggle>) => {
        ctx.beginPath();
        ctx.fillStyle = fillStyle;

        for (let x = 0; x <= 1.1; x += 0.01) wiggle(x);
        ctx.lineTo(1, 1);
        ctx.lineTo(0, 1);
        ctx.fill();
    };
};

const getScrollUnit = () => {
    return (Math.sqrt(Math.abs(window.scrollY)) / 200) * Math.sign(window.scrollY);
};

const ketchupMustardSea = (offset: number) => {
    let on = true;
    scrollUnit = getScrollUnit();
    const canvas = document.createElement('canvas');
    canvas.width = viewport.width * device.pixelRatio;
    canvas.height = viewport.height * device.pixelRatio;
    canvas.style.zIndex = `-100`;
    canvas.style.position = 'fixed';
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.opacity = '82.5%';
    canvas.style.width = '100vw';
    canvas.style.height = '100vh';
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const { width, height } = ctx.canvas;
    ctx.transform(width, 0, 0, height, 0, 0);

    const ketchupWiggle = makeWiggle(ctx, 'ketchup', offset);
    const mustardWiggle = makeWiggle(ctx, 'mustard', offset);
    const drawSea = makeDrawSea(ctx);

    document.body.prepend(canvas);

    let lastTimeNow = null;

    const animate = () => {
        if (!on) return;

        const targetScrollUnit = getScrollUnit();
        scrollUnit = lerp(scrollUnit, targetScrollUnit, 0.3);
        requestAnimationFrame(animate);

        const skipRendering =
            Math.abs(targetScrollUnit - scrollUnit) < 0.001 &&
            lastTimeNow != null &&
            Math.abs(time.now - lastTimeNow) < 0.01;
        if (skipRendering) return;

        lastTimeNow = time.now;
        ctx.clearRect(0, 0, 1, 1);
        drawSea('#FFB700', mustardWiggle);
        drawSea('#E11E2F', ketchupWiggle);
    };

    requestAnimationFrame(animate);

    return () => {
        canvas.remove();
        on = false;
    };
};

export const useKetchupMustardSea = (tide: 'high' | 'low' = 'low') => {
    useEffect(() => ketchupMustardSea(tide === 'low' ? 0.8 : 0.5), [tide]);
};
