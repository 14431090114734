export interface RatingFlavor {
    flavor: Flavor.Rating;
    flavorData: null;
}

export interface RatingWithFollowUpByStarFlavor {
    flavor: Flavor.RatingWithFollowUpByStar;
    flavorData: { followUpByStar: RatingWithFollowUpByStarFlavorData };
}

export interface MultipleChoiceFlavor {
    flavor: Flavor.MultipleChoice;
    flavorData: { choices: string[] };
}

export interface RatingWithFollowUpByStarFlavorData {
    '5': FollowUp;
    '4': FollowUp;
    '3': FollowUp;
    '2': FollowUp;
    '1': FollowUp;
}

export type FlavorUnion = RatingFlavor | RatingWithFollowUpByStarFlavor | MultipleChoiceFlavor;

export type Prompt = FlavorUnion & {
    id: number;
    title: string;
    promptType: string;
};

export interface FollowUp {
    question: string;
    reasons: string[];
    positive: boolean;
}

// This is from apricot.
// Something besides copy paste would be a good + more cohesive solution here.
// What if this were to change in apricot or blueberry
export interface MenuItem {
    id: number;
    title: string;
    MenuSection: MenuSection;
}

export interface MenuSection {
    id: number;
    title: string;
}

export interface Incentive {
    id: number;
    statement: string;
    promoCode: string;
    active: boolean;
    createdAt: string;
    updatedAt: string;
    RestaurantId: number;
}

export enum Flavor {
    Rating = 'rating',
    RatingWithFollowUpByStar = 'ratingWithFollowUpByStar',
    MultipleChoice = 'multipleChoice',
}
