import { MenuSection, MenuItemWithReviews } from '../model/menu';
import { Select } from 'antd';
import React from 'react';
import { useHydratePrompts } from '../customHooks/useHydratePrompts';
import { useHydrateMenuItems } from '../customHooks/useHydrateMenuItems';
import { PromptExt } from '../model/prompts';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useStoreState } from '../model';
import { SearchOutlined } from '@ant-design/icons';
import { countComments } from '../model/reviews';
import { uniqBy } from 'lodash-es';
import { getSubjectRelativeUrl, Subject } from '../model/subjects';
import { CommentsCount } from './CommentsCount';

const { Option, OptGroup } = Select;

const promptToOption = (prompt: PromptExt) => ({
    value: `prompt-${prompt.id}`,
    title: prompt.title,
    source: prompt,
    commentsCount: countComments(prompt.reviews),
    tags: prompt.surveyNames,
});

const menuItemToOption = (menuItem: MenuItemWithReviews) => ({
    value: `menuItem-${menuItem.id}`,
    title: menuItem.title,
    source: menuItem,
    commentsCount: countComments(menuItem.reviews),
    tags: [],
});

const promptsToSection = (prompts: PromptExt[]) => ({
    title: 'Prompts',
    options: uniqBy(prompts.map(promptToOption), 'title'),
});

const menuSectionToSection = ({ title, menuItems }: MenuSection) => ({
    title,
    options: menuItems.map(menuItemToOption),
});

interface SearchBarOption {
    value: string;
    title: string;
    source: Subject;
    commentsCount: number;
    tags: string[];
}

interface SearchBarSection {
    title: string;
    options: SearchBarOption[];
}

const useSearchBarSections = () => {
    useHydratePrompts();
    useHydrateMenuItems();
    const promptCategories = useStoreState((state) => state.promptsModel.prompts);
    const menuSections = useStoreState((state) => state.menuItemsModel.menuSections);

    const searchBarSections: SearchBarSection[] = [
        promptsToSection(promptCategories),
        ...menuSections.map(menuSectionToSection),
    ];

    const searchBarOptions = searchBarSections.flatMap((x) => x.options);

    return {
        sections: searchBarSections,
        getValue: (value) => searchBarOptions.find((x) => x.value === value)?.source,
    };
};

const StyledSelect = styled(Select)`
    filter: drop-shadow(#04000010 0 4px 5px);
    & > * {
        cursor: text;
        overflow: hidden;
        border-radius: 999px;
    }

    & .ant-select-selection__placeholder {
        margin-left: 0.2em;
    }
`;

const TagSpan = styled.span`
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.325);
    &::before {
        content: '•';
        margin: 0 0.2em;
    }
`;

const SearchIcon = styled(SearchOutlined)`
    margin-right: 0.3em;
`;

const StyledCommentsCount = styled(CommentsCount)`
    font-size: 0.9em;
    float: right;
`;

export const SearchBar = () => {
    const history = useHistory();
    const { sections, getValue } = useSearchBarSections();

    return (
        <StyledSelect
            showSearch
            placeholder={
                <>
                    <SearchIcon />
                    Search
                </>
            }
            optionFilterProp={'label'}
            onChange={(x) => {
                const value = getValue(x);
                if (value) history.push(getSubjectRelativeUrl(value));
            }}
        >
            {sections.map((section, i) => (
                <OptGroup key={`${section.title}-${i}`} label={section.title}>
                    {section.options.map((option) => (
                        <Option key={option.value} value={option.value} label={option.title}>
                            {option.title}
                            {option.tags.map((x) => (
                                <TagSpan key={`${option.value}-${x}`}>{x}</TagSpan>
                            ))}
                            {!!option.commentsCount && <StyledCommentsCount commentsCount={option.commentsCount} />}
                        </Option>
                    ))}
                </OptGroup>
            ))}
        </StyledSelect>
    );
};
