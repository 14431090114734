import { Action, action, Thunk } from 'easy-peasy';
import { sortBy, uniqBy } from 'lodash-es';
import { ModelInterface } from '.';
import { blueberryClient, SessionResponse } from '../helpers/blueberryClient';
import { NotifyNetworkError } from '../helpers/NotifyNetworkError';
import { debouncedThunk } from '../helpers/debouncedThunk';

export interface SessionsModel {
    sessions: SessionResponse[];
    sessionsUpdated: boolean;
    sessionsEverRequested: boolean;
    sessionsFullyUpdated: boolean;
    startRequestingSessions: Action<SessionsModel>;
    invalidateSessions: Action<SessionsModel>;
    updateSessions: Action<SessionsModel, SessionResponse[]>;
    // The third argument of this thunk is never used and may
    //      be left untyped.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hydrateSessions: Thunk<SessionsModel, void, any, ModelInterface>;
    _setSessionsFullyUpdated: Action<SessionsModel>;
}

const sessionsModel: SessionsModel = {
    sessions: [],
    sessionsUpdated: false,
    sessionsEverRequested: false,
    sessionsFullyUpdated: false,
    _setSessionsFullyUpdated: action((state) => {
        state.sessionsFullyUpdated = true;
    }),
    startRequestingSessions: action((state) => {
        state.sessionsEverRequested = true;
    }),
    updateSessions: action((state, payload) => {
        state.sessions.push(...payload);
        state.sessions = uniqBy(state.sessions, 'sessionUUID');
        state.sessions = sortBy(state.sessions, 'updatedAt').reverse();
        state.sessionsUpdated = true;
    }),
    invalidateSessions: action((state) => {
        state.sessions = [];
        state.sessionsEverRequested = false;
        state.sessionsFullyUpdated = false;
    }),
    hydrateSessions: debouncedThunk(async (actions, _, { getStoreState }) => {
        try {
            const fiftySessions = await blueberryClient.getRecentCompletedSessions(50);
            actions.updateSessions(fiftySessions);

            const { sessionsEverRequested } = getStoreState().sessionModel;
            if (sessionsEverRequested) return;

            actions.startRequestingSessions();
            const manySessions = await blueberryClient.getRecentCompletedSessions();
            actions.updateSessions(manySessions);
            actions._setSessionsFullyUpdated();
        } catch (e) {
            NotifyNetworkError('Error fetching recent feedback.');
            console.error('sessions hydration error:', e);
        }
    }),
};

export default sessionsModel;
