import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import { useStoreState } from './model';
import wrapHOC from './components/Wrapper';
import Login from './components/Login';
import 'react-notifications-component/dist/theme.css';
import './css/App.less';

import AccountInfo from './components/AccountInfo';
import ChangePassword from './components/ChangePassword';
import { NotFoundPage } from './components/NotFound';
import { HomePage } from './components/HomePage';
import { MenuItemRouter } from './components/MenuItemRouter';
import { PromptRouter } from './components/PromptRouter';
import { GuestEmailsPage } from './components/GuestEmailsPage';
import ScrollToTop from 'react-router-scroll-top';
import { useHydrateAll } from './customHooks/useHydrateAll';
import { StarRouter } from './components/StarRouter';
import { GuestRouter } from './components/GuestRouter';
import { EditSurveyRouter } from './components/editSurvey/EditSurveyRouter';
import { Modal } from 'antd';
import { JoinPage, SignUpPage } from './components/signUp/SignUpPage';
import { useStoreRehydrated } from 'easy-peasy';
import { useHeapIdentify } from './customHooks/useHeapIdentify';
import { SettingsPage } from './components/settings/SettingsPage';

const App = () => {
    const rehydrated = useStoreRehydrated();
    const isLoggedIn = useStoreState((state) => state.userModel.isLoggedIn);
    useHeapIdentify();

    if (!rehydrated) return null;

    return (
        <>
            <ReactNotification />
            <Router>
                <KillModalsWhenLocationChanges />
                <ScrollToTop />
                {isLoggedIn && <LoggedInSwitch />}
                {!isLoggedIn && (
                    <Switch>
                        <Route path="/login" exact component={Login} />
                        <Route path="/sign-up" exact component={SignUpPage} />
                        <Route path="/join/:restaurantUuid" exact component={JoinPage} />
                        <Redirect from="*" to="/login" />
                    </Switch>
                )}
            </Router>
        </>
    );
};

const KillModalsWhenLocationChanges = () => {
    const location = useLocation();
    useEffect(Modal.destroyAll, [location]);
    return null;
};

const LoggedInSwitch = () => {
    useHydrateAll();

    return (
        <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/changePassword" exact component={wrapHOC(ChangePassword, 'NoPaywall')} />
            <Route path="/account" exact component={wrapHOC(AccountInfo, 'NoPaywall')} />
            <Route path="/dashboard" exact component={wrapHOC(HomePage)} />
            <Route path="/item/:id" exact component={wrapHOC(MenuItemRouter)} />
            <Route path="/prompt/:id" exact component={wrapHOC(PromptRouter)} />
            <Route path="/star/:id" exact component={wrapHOC(StarRouter)} />
            <Route path="/guest/:email" exact component={wrapHOC(GuestRouter)} />
            <Route path="/emails" exact component={wrapHOC(GuestEmailsPage)} />
            <Route path="/settings" exact component={wrapHOC(SettingsPage)} />
            <Route
                path={['/surveys', '/survey/:surveyId', '/survey/:surveyId/preview', '/menu/:menuId']}
                exact
                component={wrapHOC(EditSurveyRouter, 'NoPaywall')}
            />
            <Route path="/join/:restaurantUuid" exact component={JoinPage} />
            <Redirect from="/" exact to="/dashboard" />
            <Route path="*" component={wrapHOC(NotFoundPage, 'NoPaywall')} />
        </Switch>
    );
};

export default App;
