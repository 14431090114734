import React, { useState } from 'react';
import { GrazeLayout } from '../brand/GrazeLayout';
import { useKetchupMustardSea } from '../../customHooks/useKetchupMustardSea';
import { useAsyncEffect } from '../../customHooks/useAsyncEffect';
import { blueberryClient } from '../../helpers/blueberryClient';
import { AsyncReturnType, Undefined } from '../../helpers/utilityTypes';
import { VerticalCenteredGrazeSpinner } from '../brand/VerticalCenteredGrazeSpinner';
import styled from 'styled-components';
import { GreenButton } from '../brand/GreenButton';
import { PlusOutlined } from '@ant-design/icons';
import { GrazeCard } from '../brand/GrazeCard';
import { sendInviteEmail } from './sendInviteEmail';

export function SettingsPage() {
    useKetchupMustardSea();
    const [teamMembers, setTeamMembers] = useState(Undefined<AsyncReturnType<typeof blueberryClient.getTeamMembers>>());
    useAsyncEffect(async () => {
        setTeamMembers(await blueberryClient.getTeamMembers());
    }, []);

    if (!teamMembers) return <VerticalCenteredGrazeSpinner />;

    return (
        <GrazeLayout>
            <h1>Settings</h1>
            <Row>
                <h2>Team</h2>
                <GreenButton onClick={sendInviteEmail}>
                    <PlusOutlined />
                    Invite Teammate
                </GreenButton>
            </Row>
            <GrazeCard>
                {teamMembers.length === 0 && (
                    <NoTeam>
                        <h2>No teammates yet!</h2>
                    </NoTeam>
                )}
                {teamMembers.length > 0 && (
                    <Table>
                        <tbody>
                            {teamMembers.map((x) => (
                                <tr key={JSON.stringify(x)}>
                                    <td>
                                        {x.firstName} {x.lastName}
                                    </td>
                                    <td>{x.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </GrazeCard>
        </GrazeLayout>
    );
}

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
    line-height: 1;
    * {
        margin: 0;
    }
    margin-bottom: 0.7em;
`;

const NoTeam = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    * {
        margin: 0;
        opacity: 75%;
    }
`;

const Table = styled.table`
    width: 100%;
    td {
        padding: 0.5em 0;
    }
    tr:not(:last-child) {
        border-bottom: solid 1px #e8e8e8;
    }
    tr:first-child td {
        padding-top: 0;
    }
    tr:last-child td {
        padding-bottom: 0;
    }
`;
