import { useEditSurveys } from './EditSurveysContext';
import { useCallback } from 'react';
import { debounce, groupBy } from 'lodash-es';
import { blueberryClient } from '../../helpers/blueberryClient';
import { NotifyNetworkError } from '../../helpers/NotifyNetworkError';
import { arrayMove } from '../../helpers/arrayMove';

const useCurrentMenuValues = () => {
    const {
        surveys: { menus, menuSections, menuItems },
        params,
    } = useEditSurveys();
    const currentMenu = menus.find((x) => x.id === params.menuId);
    if (!currentMenu)
        return {
            found: false,
        };

    return {
        id: currentMenu.id,
        title: currentMenu.title,
        found: true,
        sections: menuSections
            .filter((x) => x.menuId === currentMenu.id)
            .map((x) => ({
                items: menuItems.filter((y) => y.menuSectionId === x.id),
                ...x,
            })),
    };
};

export const useCurrentMenu = () => {
    const values = useCurrentMenuValues();
    const { _setSurveys, surveys } = useEditSurveys();

    const updateMenuSectionOrder = useCallback(
        debounce(
            async ({ sortedIds }) => {
                try {
                    await blueberryClient.updateMenuSectionOrder({ sortedIds });
                } catch (e) {
                    NotifyNetworkError('Error updating menu section order.');
                    console.error('updateMenuSectionOrder error:', e);
                }
            },
            1000,
            { leading: false, trailing: true }
        ),
        []
    );
    const adjustMenuSectionPosition = useCallback(
        async ({ id, shift }) => {
            const { sections } = values;
            const index = sections.findIndex((x) => x.id === id);
            const newIndex = Math.max(0, Math.min(sections.length - 1, index + shift));
            if (index === newIndex) return;
            arrayMove(sections, index, newIndex);

            const currentMenuSectionsById = groupBy(sections, 'id');
            const newSections = surveys.menuSections.filter((x) => !(x.id in currentMenuSectionsById));
            newSections.push(...sections);
            surveys.menuSections = newSections;

            _setSurveys({ ...surveys });
            await updateMenuSectionOrder({ sortedIds: sections.map((x) => x.id) });
        },
        [updateMenuSectionOrder, surveys, values, _setSurveys]
    );

    return {
        adjustMenuSectionPosition,
        ...values,
    };
};
