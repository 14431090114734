import * as React from 'react';
import { Tag } from 'antd';
import { formatRating } from '../helpers/formatRating';
import styled from 'styled-components';
import { YellowStar } from './brand/YellowStar';

export interface RatingTagProps {
    rating: number;
}

const getTagColor = (averageRating: number): 'green' | 'red' => {
    return averageRating >= 4.0 ? 'green' : 'red';
};

const StarIcon = styled(YellowStar)`
    font-size: 13px;
    padding: 3px;
`;

const RatingTag: React.SFC<RatingTagProps> = ({ rating }: RatingTagProps) => {
    if (!rating) {
        return <Tag>None</Tag>;
    }
    return (
        <Tag color={getTagColor(rating)}>
            {formatRating(rating)}
            <StarIcon />
        </Tag>
    );
};

export default RatingTag;
