import { useSubscription } from '../../customHooks/useSubscription';
import React from 'react';
import { GrazeCard } from '../brand/GrazeCard';
import { ManipulateSubscriptionButton } from '../manageSubscription/ManageSubscription';
import styled, { createGlobalStyle } from 'styled-components';

const Div = styled.div`
    z-index: 2;
    position: absolute;
    max-width: 1000px;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    text-align: center;
    h2 {
        font-size: 30px;
    }
    p {
        font-size: 20px;
    }
    h2,
    p {
        text-align: left;
    }
`;

const NoScroll = createGlobalStyle`
  html {
    overflow: hidden;
    height: 100vh;
  }
`;

export const Paywall = () => {
    const subscription = useSubscription();

    if (!subscription) return null;

    return (
        <Div>
            <NoScroll />
            <GrazeCard>
                <h2>Hey there&mdash;</h2>
                <p>Please subscribe to see feedback about your restaurant.</p>
                <ManipulateSubscriptionButton size="large" />
            </GrazeCard>
        </Div>
    );
};
