import { Select } from 'antd';
import React from 'react';
import { OverviewTimeRange, useOverviewTimeRange } from '../../customHooks/useOverviewTimeRange';
import { GrazeSelect } from '../brand/GrazeSelect';

const { Option } = Select;

export const OverviewTimeRangeSelect = () => {
    const [timeRange, setTimeRange] = useOverviewTimeRange();

    return (
        <GrazeSelect value={timeRange} defaultValue={timeRange} onChange={(x: OverviewTimeRange) => setTimeRange(x)}>
            <Option value="30-days">30 Days</Option>
            <Option value="all-time">All-Time</Option>
        </GrazeSelect>
    );
};
