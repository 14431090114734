import React, { useState } from 'react';
import { blueberryClient } from '../../helpers/blueberryClient';
import { stripeClient } from '../../helpers/stripeClient';
import { VerticalCenteredGrazeSpinner } from '../brand/VerticalCenteredGrazeSpinner';
import { useSubscription } from '../../customHooks/useSubscription';
import { GreenButton } from '../brand/GreenButton';

export const ManageSubscription = () => {
    const subscription = useSubscription();

    if (!subscription) return null;

    const { status, goodThrough } = subscription;

    return (
        <>
            {status === 'NeverPaid' && <p>No active Graze Analytics subscription</p>}
            {status === 'PaymentFailed' && <p>Payment failed. Please manage your subscription.</p>}
            {status === 'PaymentDue' && <p>Payment due. Please manage your subscription.</p>}
            {status === 'Paid' && <p>Paid through {goodThrough.format('D MMMM YYYY')}</p>}
            <ManipulateSubscriptionButton />
        </>
    );
};

const gotoStripePortal = async (mode: 'create' | 'manage') => {
    if (mode === 'create') {
        const { stripeSessionId } = await blueberryClient.createSubscription();
        await stripeClient.redirectToCheckout({ sessionId: stripeSessionId });
    } else if (mode === 'manage') {
        const { stripeBillingPortalSessionUrl } = await blueberryClient.getSubscriptionPortalSession();
        window.location.href = stripeBillingPortalSessionUrl;
    }
};

export const ManipulateSubscriptionButton = (props) => {
    const subscription = useSubscription();
    const [loading, setLoading] = useState(false);
    if (!subscription) return null;

    const { status, hasStripeCustomer } = subscription;
    const canCreateSubscription = status === 'NeverPaid' || (status !== 'Paid' && !hasStripeCustomer);

    const mode = canCreateSubscription ? 'create' : !canCreateSubscription && hasStripeCustomer ? 'manage' : 'nothing';

    if (mode === 'nothing') return null;

    const fn = async () => {
        setLoading(true);
        await gotoStripePortal(mode);
    };

    return (
        <GreenButton onClick={fn} loading={loading} {...props}>
            {mode === 'manage' && 'Manage Subscription'}
            {mode === 'create' && 'Subscribe'}
        </GreenButton>
    );
};
