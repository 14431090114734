import { EmojiName, getEmojiUrl } from './getEmojiUrl';

export const preloadEmoji = (...names: EmojiName[]) => Promise.all(names.map(preloadOneEmoji));

const preloadOneEmoji = (name: EmojiName) => {
    const image = new Image();
    const url = getEmojiUrl(name);

    if (!url) return Promise.resolve();

    return new Promise<void>((resolve) => {
        image.onload = () => resolve();
        image.onerror = () => resolve();
        image.src = url;
    });
};
