import React, { useRef } from 'react';
import styled from 'styled-components';
import { SurveyPrompt } from '../../helpers/blueberryClient';
import { LabeledReviewInput } from '@orchard/peach';
import { GrazeCard } from '../brand/GrazeCard';
import { useAsyncEffect } from '../../customHooks/useAsyncEffect';
import { animateQuestionCard } from './animateQuestionCard';
import { useHover } from 'react-use';

export const QuestionCardOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    transition: opacity 0.4s;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    > button {
        margin: 0;
    }
`;

const EditPromptDiv = styled(GrazeCard)`
    position: relative;
    cursor: pointer;
    :hover ${QuestionCardOverlay} {
        opacity: 100%;
    }
    overflow: hidden;
`;

const InputDiv = styled.div`
    > * {
        pointer-events: none;
    }
    div {
        margin: 0;
    }
    > div {
        margin: 0 auto;
        width: 100%;
    }
`;

const AsksFollowUpDiv = styled.div`
    position: relative;
    @media only screen and (min-width: 500px) {
        bottom: 12px;
        left: 12px;
    }
    align-self: center;
    padding: 0.2em 0.5em;
    font-size: 10px;
    border-radius: 4px;
    background-color: #2abd73;
    color: white;
`;

const AsksFollowUp = () => <AsksFollowUpDiv>Asks Follow-up</AsksFollowUpDiv>;

const Label = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const QuestionCard = ({ prompt, onClick, children }: { prompt: SurveyPrompt; children?; onClick? }) => {
    const Badge = prompt.flavor === 'ratingWithFollowUpByStar' ? <AsksFollowUp /> : <></>;
    return (
        <EditPromptDiv onClick={onClick}>
            <InputDiv>
                <LabeledReviewInput
                    label={
                        <Label>
                            <div>{prompt.title}</div>
                            {Badge}
                        </Label>
                    }
                    onChange={() => {}}
                    placeholder="Tell us more..."
                    flavor={prompt}
                />
            </InputDiv>
            {children}
        </EditPromptDiv>
    );
};

const AnimatedEditPromptDiv = styled(EditPromptDiv)`
    min-height: 294px;
`;

export const AnimatedQuestionCard = ({ prompt, onClick, children }: { prompt: SurveyPrompt; children?; onClick? }) => {
    const component = (isHovered: boolean) => (
        <AnimatedEditPromptDiv onClick={onClick}>
            {!isHovered && (
                <InputDiv>
                    <LabeledReviewInput
                        label={prompt.title}
                        onChange={() => {}}
                        placeholder="Tell us more..."
                        flavor={prompt}
                    />
                </InputDiv>
            )}
            {isHovered && <AnimatedInputDiv prompt={prompt} />}
            {children}
        </AnimatedEditPromptDiv>
    );

    const [hoveredComponent] = useHover(component);

    return hoveredComponent;
};

const AnimatedInputDiv = ({ prompt }) => {
    const ref = useRef();
    useAsyncEffect(
        (ct) => ref.current && animateQuestionCard(prompt, ref.current, ct),
        [!ref.current, JSON.stringify(prompt)]
    );

    return (
        <InputDiv ref={ref}>
            <LabeledReviewInput
                label={prompt.title}
                onChange={() => {}}
                placeholder="Tell us more..."
                flavor={prompt}
            />
        </InputDiv>
    );
};
