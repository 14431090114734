import React from 'react';
import { AddPromptButton } from './AddButton';
import { HidePromptButton } from './HideButton';
import styled from 'styled-components';
import { useCurrentSurvey } from './EditSurvey';
import { SurveyPrompt } from '../../helpers/blueberryClient';
import { QuestionCard, QuestionCardOverlay } from './QuestionCard';

const EditPromptsDiv = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media only screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
    > * {
        margin: 6px;
        @media only screen and (min-width: 1001px) {
            margin: 12px;
            :nth-child(1),
            :nth-child(2) {
                margin-top: 0;
            }
            :nth-child(odd) {
                margin-left: 0;
            }
            :nth-child(even) {
                margin-right: 0;
            }
        }
    }
`;

export const EditPrompts = () => {
    const { prompts } = useCurrentSurvey();
    return (
        <EditPromptsDiv>
            {prompts.map((x) => (
                <EditPrompt prompt={x} key={x.id} />
            ))}
            <AddPromptButton />
        </EditPromptsDiv>
    );
};

const DarkQuestionCardOverlay = styled(QuestionCardOverlay)`
    background-color: #8080804c;
`;

const EditPrompt = ({ prompt }: { prompt: SurveyPrompt }) => {
    return (
        <QuestionCard prompt={prompt}>
            <DarkQuestionCardOverlay>
                <HidePromptButton title={prompt.title} id={prompt.id} surveyId={prompt.surveyId} />
            </DarkQuestionCardOverlay>
        </QuestionCard>
    );
};
