import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { blueberryClient } from '../../helpers/blueberryClient';
import { useCurrentSurvey } from './EditSurvey';
import { useLoadSurveysCallback } from './EditSurveysContext';
import { manipulateIncentive } from './manipulateIncentive';

const createNewIncentive = (createIncentiveOnSurvey) =>
    manipulateIncentive(
        {
            title: 'Create new incentive',
            okText: 'Create',
            defaultStatement: '',
            defaultPromoCode: '',
            defaultExpirationDays: 35,
        },
        createIncentiveOnSurvey
    );

const useCreateIncentiveOnSurvey = () => {
    const survey = useCurrentSurvey();
    return useLoadSurveysCallback(
        ({ statement, promoCode, expirationDays }) =>
            blueberryClient.createIncentiveOnSurvey({ surveyId: survey.id, statement, promoCode, expirationDays }),
        [survey.id]
    );
};

export const AddIncentiveButton = () => {
    const createIncentiveOnSurvey = useCreateIncentiveOnSurvey();

    return (
        <Button size="large" onClick={() => createNewIncentive(createIncentiveOnSurvey)}>
            <PlusOutlined />
            Add Incentive
        </Button>
    );
};
