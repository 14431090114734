import React, { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import styled from 'styled-components';
import { MultipleChoiceFlavor } from '../../types/blueberryTypes';
import { MultipleChoiceReviewData, ReviewInputPropsBase } from './ReviewInput';
import { ControlledCheckableButton } from '../PickFoodPage/CheckableButton';

interface MultipleChoiceInput extends ReviewInputPropsBase<MultipleChoiceReviewData> {
    flavor: MultipleChoiceFlavor;
}

export const MultipleChoiceInput = ({
    onChange,
    defaultValue,
    flavor: {
        flavorData: { choices },
    },
}: MultipleChoiceInput) => {
    const [data, setData] = useState<MultipleChoiceReviewData>(defaultValue?.data ?? { choice: null });

    useUpdateEffect(() => {
        onChange({ rating: null, comment: null, data });
    }, [JSON.stringify(data)]);

    return (
        <>
            <ChoicesDiv data-any_selected={!!data.choice}>
                {choices.map((x, i) => (
                    <ControlledCheckableButton
                        applyStyles={false}
                        checked={x === data.choice}
                        onCheck={() => setData({ choice: x })}
                        onUncheck={() => setData({ choice: null })}
                        key={`${x}_${i}`}
                    >
                        {x}
                    </ControlledCheckableButton>
                ))}
            </ChoicesDiv>
        </>
    );
};

const ChoicesDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.4em;

    button:active {
        transform: translate(0, 0.3em);
        box-shadow: 0 0.2em #d9d9d9;
    }

    button {
        border-radius: 0.3em;
        border: solid 1px #d9d9d9;
        box-shadow: 0 0.1em #d9d9d9;
        transform-origin: bottom;
        transition: box-shadow 0.125s linear, transform 0.125s linear, opacity 0.175s;
        margin-bottom: 0.5em;
    }

    > :not([data-checked='true']) {
        box-shadow: 0 0.5em #d9d9d9;
    }

    &[data-any_selected='true'] :not([data-checked='true']) {
        opacity: 0.5;
    }

    [data-checked='true'] {
        background-color: #e7f5ff;
        transform: translate(0, 0.4em);
        box-shadow: 0 0.1em #a2c9d8;
        border-color: #a2c9d8;
    }
`;
