import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { blueberryClient } from '../../helpers/blueberryClient';
import { useCurrentSurvey } from './EditSurvey';
import { showLoadingSpinner } from './ask';
import { useLoadSurveysCallback } from './EditSurveysContext';
import { useHistory } from 'react-router-dom';

type ImperativeModal = ReturnType<typeof Modal.confirm>;

const CreateNewMenu = ({ modal, createMenuOnSurvey }: { modal: ImperativeModal; createMenuOnSurvey }) => {
    const [menuName, setMenuName] = useState('');

    useEffect(
        () =>
            modal.update({
                okButtonProps: { disabled: menuName.length === 0 },
                onOk: async () => {
                    showLoadingSpinner(modal);
                    await createMenuOnSurvey(menuName);
                },
            }),
        [menuName, modal, createMenuOnSurvey]
    );
    return (
        <>
            <Input
                size="large"
                placeholder="Enter menu name"
                defaultValue={menuName}
                onChange={(x) => setMenuName(x.target.value)}
                autoFocus
            />
        </>
    );
};

const createNewMenu = (createMenuOnSurvey) => {
    const modal = Modal.confirm({
        title: 'Create new menu',
        okButtonProps: { disabled: true },
        okText: 'Create',
        icon: null,
    });
    modal.update({ content: <CreateNewMenu modal={modal} createMenuOnSurvey={createMenuOnSurvey} /> });
};

export const AddMenuButton = () => {
    const survey = useCurrentSurvey();
    const createMenuOnSurveyAndLoadSurveys = useLoadSurveysCallback(
        (title) => blueberryClient.createMenuOnSurvey({ title, surveyId: survey.id }),
        [survey.id]
    );
    const history = useHistory();
    const createMenuOnSurvey = useCallback(
        async (title) => {
            const newMenu = await createMenuOnSurveyAndLoadSurveys(title);
            history.push(`/menu/${newMenu.id}`);
        },
        [createMenuOnSurveyAndLoadSurveys, history]
    );
    return (
        <Button size="large" onClick={() => createNewMenu(createMenuOnSurvey)}>
            <PlusOutlined />
            Add Menu
        </Button>
    );
};
