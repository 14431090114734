import { Heap } from '../helpers/analytics';
import { useStoreState } from '../model';
import { useEffect } from 'react';

export function useHeapIdentify() {
    const loggedIn = useStoreState((x) => x.userModel.isLoggedIn);
    const contents = useStoreState((x) => x.userModel.jwtContents);
    useEffect(() => {
        if (!loggedIn) return Heap.resetIdentity();
        Heap.identify(contents.username);
        Heap.addEventProperties({
            email: contents.email,
            firstName: contents.firstName,
            lastName: contents.lastName,
            restaurantId: contents.restaurantId,
            username: contents.username,
        });
    }, [JSON.stringify(contents), loggedIn]);
}
