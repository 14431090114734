import React from 'react';
import styled from 'styled-components';
import { numberOrZero } from '../helpers/numberOrZero';

const Div = styled.div`
    flex-grow: 1;
    border-radius: 2px;
    background-color: #e8e8e8;
    height: 1em;
    overflow: hidden;

    > span {
        border-radius: 2px;
        display: block;
        height: 100%;
        background-color: #ffb700;
    }
`;

export const FilledBar = ({ unit = 1, ...rest }) => {
    const percent = Math.ceil(numberOrZero(unit) * 100);
    return (
        <Div {...rest}>
            <span style={{ width: `${percent}%` }} />
        </Div>
    );
};
