import { sleep } from './sleep';

export function wait<T>(predicate: () => T) {
    const intervalMs = 33;

    return new Promise<T>(async (resolve) => {
        let value;
        while (!(value = predicate())) await sleep(intervalMs);
        resolve(value);
    });
}
