import { MenuItem } from './menu';
import { Prompt } from './prompts';
import { isMenuItemReview, Review } from './reviews';

export type Subject = MenuItem | Prompt;

export const isPrompt = (subject: Subject): subject is Prompt => 'promptType' in subject;
export const isMenuItem = (subject: Subject): subject is MenuItem => !isPrompt(subject);

export const getSubject = (review: Review) => (isMenuItemReview(review) ? review.MenuItem : review.Prompt);

export const getSubjectRelativeUrl = (subject: Subject) =>
    isMenuItem(subject) ? `/item/${subject.id}` : `/prompt/${subject.id}`;
