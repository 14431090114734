import React, { useState } from 'react';
import { SessionCard } from './SessionCard';
import { CommentCard } from './CommentCard';
import styled from 'styled-components';
import { isTextHighlighted } from '../../helpers/isTextHighlighted';
import { ReviewToSession } from '../../model/reviews';

interface Props {
    review: ReviewToSession;
    CommentComponent: typeof CommentCard;
}

const Div = styled.div`
    cursor: pointer;
    & > *:nth-child(1) {
        transition: background-color 0.3s;
    }
    & > *:nth-child(1):hover {
        background-color: #fbfbfb;
    }
`;

export const ExpandableCommentCard = ({ review, CommentComponent }: Props) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <Div
            onClick={() => {
                if (!isTextHighlighted()) setExpanded(!expanded);
            }}
        >
            {!expanded && <CommentComponent review={review} />}
            {expanded && <SessionCard session={review.session} />}
        </Div>
    );
};
