import { store } from 'react-notifications-component';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum MessageType {
    success = 'success',
    danger = 'danger',
    info = 'info',
    default = 'default',
    warning = 'warning',
}

export default function Notify(title, message, type): void {
    store.addNotification({
        title,
        message,
        type,
        insert: 'bottom',
        container: 'bottom-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
            duration: 4000,
        },
    });
}
