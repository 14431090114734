import React from 'react';
import { FeedbackCard } from './FeedbackCard';
import { ContextText } from '../ContextText';
import { ReviewToSession } from '../../model/reviews';
import ReviewItem from '../ReviewItem';

interface Props {
    review: ReviewToSession;
}

export const CommentCard = ({ review }: Props) => {
    return (
        <FeedbackCard rating={review.starRating}>
            <ReviewItem review={review} doNotShowHeader />
            <ContextText session={review.session} />
        </FeedbackCard>
    );
};
