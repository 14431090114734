import React from 'react';
import styled from 'styled-components';
import { Feed } from './home/Feed';
import { useKetchupMustardSea } from '../customHooks/useKetchupMustardSea';
import { Overview } from './home/Overview';
import { useHydrateSessions } from '../customHooks/useHydrateSessions';
import { VerticalCenteredGrazeSpinner } from './brand/VerticalCenteredGrazeSpinner';
import { GrazeLayout } from './brand/GrazeLayout';
import { SearchBar } from './SearchBar';
import { useSubscription } from '../customHooks/useSubscription';

export const HomePage = () => {
    useKetchupMustardSea();
    const { sessionsHydrated } = useHydrateSessions();
    const subscription = useSubscription();

    if (!sessionsHydrated || !subscription) return <VerticalCenteredGrazeSpinner />;
    return <Home />;
};

const Home = () => {
    return (
        <HomeLayout>
            <SearchBar />
            <Overview />
            <Feed />
        </HomeLayout>
    );
};

const HomeLayout = styled(GrazeLayout)`
    & > * {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%;
    }
    & > section {
        margin: 10px auto;
    }
`;
