import { Input } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { TextAreaRef } from 'antd/es/input/TextArea';

const TextArea = styled(Input.TextArea)`
    resize: none;
    border-radius: 8px;
`;

export type ReviewComment = string | null;

interface Props {
    value: ReviewComment;
    placeholder?: string;
    onChange(comment: ReviewComment): void;
    innerRef?: React.Ref<TextAreaRef>;
}

export const CommentInput = ({ value, onChange, placeholder, innerRef }: Props) => {
    return (
        <TextArea
            placeholder={placeholder}
            autoSize={{ minRows: 2 }}
            value={value ?? ''}
            onChange={(x) => onChange(x.target.value)}
            ref={innerRef}
        />
    );
};
