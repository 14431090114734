import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useBurst } from '../../hooks/useBurst';

const Button = styled.button`
    display: block;
    background-color: #f2f2f2;
    padding: 0.5em 1em;
    border-radius: 999px;
    border: none;
    transition: transform 0.175s;
    &:active {
        transform: scale(0.9, 0.9);
    }
`;

interface Props extends HTMLAttributes<any> {
    checkedColor?: string;
    defaultChecked: boolean;
    onCheck(): void;
    onUncheck(): void;
}

const palette = ['#F6484B', '#F8C113', '#5FB97C', '#9F88FB'];
let paletteIndex = 0;

const nextColor = () => palette[paletteIndex++ % palette.length];

export const CheckableButton = ({ defaultChecked, onCheck, onUncheck, ...rest }: Props) => {
    const [checked, setChecked] = useState(defaultChecked);
    return (
        <ControlledCheckableButton
            checked={checked}
            onCheck={() => {
                setChecked(true);
                onCheck();
            }}
            onUncheck={() => {
                setChecked(false);
                onUncheck();
            }}
            {...rest}
        />
    );
};

interface ControlledProps extends HTMLAttributes<any> {
    checkedColor?: string;
    applyStyles?: boolean;
    checked: boolean;
    onCheck(): void;
    onUncheck(): void;
}

export const ControlledCheckableButton = ({
    checked,
    onCheck,
    onUncheck,
    children,
    checkedColor,
    applyStyles,
    ...rest
}: ControlledProps) => {
    const [backgroundColor, setBackgroundColor] = useState('');
    const buttonRef = useRef<HTMLElement>();
    const burst = useBurst(buttonRef);
    useEffect(() => {
        if (checked) setBackgroundColor(checkedColor ?? nextColor());
    }, [checked]);

    const onClick = () => {
        if (!checked) {
            onCheck();
            burst('yellowLines');
        } else onUncheck();
    };
    return (
        <Button
            {...rest}
            onClick={onClick}
            style={checked && applyStyles !== false ? { backgroundColor, color: 'white' } : undefined}
            data-checked={checked}
            ref={buttonRef as any}
        >
            {children}
        </Button>
    );
};
