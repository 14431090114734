import { DependencyList, useEffect } from 'react';

export interface CleanupToken {
    isCleaningUp: boolean;
}

export const useAsyncEffect = (promiseFn: (cleanupToken: CleanupToken) => Promise<void>, deps?: DependencyList) =>
    useEffect(() => {
        const cleanupToken: CleanupToken = { isCleaningUp: false };
        setTimeout(async () => await promiseFn(cleanupToken));
        return () => {
            cleanupToken.isCleaningUp = true;
        };
        // eslint-disable-next-line
    }, deps);
