import { useEditSurveys, useLoadSurveysCallback } from './EditSurveysContext';
import { blueberryClient } from '../../helpers/blueberryClient';
import { ask } from './ask';
import { Button, Dropdown, Menu } from 'antd';
import { HeaderWithHiddenButtons } from './HeaderWithHiddenButtons';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { HideMenuSectionButton } from './HideButton';
import { AddMenuItemButton, AddMenuSectionButton } from './AddButton';
import React, { useState } from 'react';
import { EditSurveyLayout } from './EditSurvey';
import { GrazeCard } from '../brand/GrazeCard';
import { useCurrentMenu } from './useCurrentMenu';
import { HeaderWithTag } from './HeaderWithTag';
import { NotFound } from '../NotFound';
import { EditableText } from '../brand/EditableText';
import { editMenuItemSpelling } from './editMenuItemSpelling';
import { useStoreActions } from '../../model';

const EditMenuItem = ({ id, title, active }) => {
    const hideMenuItem = useLoadSurveysCallback(() => blueberryClient.hideMenuItem(id), [id]);
    const invalidateSessions = useStoreActions((actions) => actions.sessionModel.invalidateSessions);
    const loadSurveys = useLoadSurveysCallback(() => {
        invalidateSessions();
        return Promise.resolve();
    }, [invalidateSessions]);

    if (!active) return <></>;

    const onHideActionClick = () =>
        ask({
            title: `Hide ${title}?`,
            content: (
                <>
                    This will remove {title} from the survey. No existing feedback about {title} will be lost.
                </>
            ),
            onOkPromiseFn: hideMenuItem,
        });

    const menu = (
        <Menu>
            <Menu.Item onClick={onHideActionClick}>Hide</Menu.Item>
            <Menu.Item onClick={() => editMenuItemSpelling({ id, title } as any, loadSurveys as any)}>
                Edit Spelling
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Dropdown overlay={menu}>
                <Button shape="round">{title}</Button>
            </Dropdown>
        </>
    );
};

export const useMenuItemsForSection = (menuSectionId) =>
    useEditSurveys().surveys.menuItems.filter((x) => x.menuSectionId === menuSectionId);

const EditMenuSection = ({ title, id, active, items }) => {
    const { adjustMenuSectionPosition } = useCurrentMenu();
    const [editing, setEditing] = useState(false);
    const updateMenuSectionTitle = useLoadSurveysCallback(
        (title: string) => blueberryClient.updateMenuSection({ menuSectionId: id, title }),
        [id]
    );

    if (!active) return null;

    return (
        <section>
            {!editing && (
                <HeaderWithHiddenButtons>
                    <h2>{title}</h2>
                    <Button onClick={() => adjustMenuSectionPosition({ id, shift: -1 })} shape="circle" size="small">
                        <ArrowUpOutlined />
                    </Button>
                    <Button onClick={() => adjustMenuSectionPosition({ id, shift: 1 })} shape="circle" size="small">
                        <ArrowDownOutlined />
                    </Button>
                    <HideMenuSectionButton title={title} id={id} />
                    <Button shape="round" size="small" onClick={() => setEditing(true)}>
                        Edit
                    </Button>
                </HeaderWithHiddenButtons>
            )}
            {editing && (
                <h2>
                    <EditableText
                        value={title}
                        autofocus
                        setValue={async (x) => {
                            await updateMenuSectionTitle(x);
                            setEditing(false);
                        }}
                        onCancel={() => setEditing(false)}
                    />
                </h2>
            )}
            {items.map((x) => (
                <EditMenuItem key={x.id} {...x} />
            ))}
            <AddMenuItemButton menuSectionId={id} />
        </section>
    );
};

export const EditMenu = () => {
    const { sections, found } = useCurrentMenu();

    if (!found) return <NotFound />;

    return (
        <EditSurveyLayout>
            <MenuTitle />
            <GrazeCard>
                {sections.map((x) => (
                    <EditMenuSection key={x.id} {...x} />
                ))}
                <AddMenuSectionButton />
            </GrazeCard>
        </EditSurveyLayout>
    );
};

const MenuTitle = () => {
    const { title, id } = useCurrentMenu();
    const updateMenuTitle = useLoadSurveysCallback((title) => blueberryClient.updateMenu({ menuId: id, title }), [id]);

    return <HeaderWithTag title={title} tag="Menu" color="#058CED" updateTitle={updateMenuTitle} editable />;
};
