import { environment } from './environment';

function getPeachUrl() {
    switch (environment) {
        case 'dev':
            return 'https://devreviews.grazeanalytics.com';
        case 'prod':
            return 'https://reviews.grazeanalytics.com';
    }

    return 'http://localhost:3001';
}

export const peachUrl = getPeachUrl();
