import { useHistory } from 'react-router-dom';
import { blueberryClient } from '../helpers/blueberryClient';
import { useStoreActions, useStoreState } from '../model';
import store, { initialState } from '../store';

export function useSwitchRestaurant() {
    const history = useHistory();
    const setJWTContents = useStoreActions((actions) => actions.userModel.setJwtContents);
    const currentRestaurantId = useStoreState((state) => state.userModel.currentRestaurant.id);

    return async (restaurantId: number) => {
        if (currentRestaurantId === restaurantId) return;

        const jwt = await blueberryClient.getJwtForRestaurant(restaurantId);
        setJWTContents(jwt);

        const userModel = store.getState().userModel;

        store.dispatch.setState({
            ...initialState,
            userModel,
        });

        history.replace('/dashboard');
    };
}
