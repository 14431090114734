import React, { useCallback } from 'react';
import mojs from '@mojs/core';
import { getVector } from '../utils/getVector';

export const useBurst = <T extends HTMLElement>(elemRef: React.RefObject<T | undefined | null>) =>
    useCallback(
        (style: Style) => {
            if (!elemRef?.current) return;
            const burst = getBurst(style);
            const [x, y] = getVector(elemRef.current);
            burst.replay().tune({ left: `${x}px`, top: `${y}px` });
        },
        [elemRef?.current]
    );

type Style = 'yellowLines';

const getBurst = (style: Style) => {
    switch (style) {
        case 'yellowLines':
            return yellowLinesBurst;
    }
};

const yellowLinesBurst = new mojs.Burst({
    className: 'above_modal',
    isShowEnd: false,
    radius: { 20: 35 },
    count: 9,
    children: {
        shape: 'line',
        radius: { 5: 0 },
        scale: 1,
        stroke: '#f5a302',
        strokeDasharray: '100%',
        duration: 400,
        easing: 'quad.out',
    },
});
