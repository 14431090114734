import React from 'react';

import { FollowUp, Review } from '../model/reviews';
import styled from 'styled-components';
import { YellowStar } from './brand/YellowStar';
import { SubjectTitle } from './SubjectTitle';
import { getSubject } from '../model/subjects';

interface ReviewItemProps {
    review: Review;
    doNotShowHeader?: boolean;
    doNotShowStarRating?: boolean;
}

const Header = styled.div`
    font-weight: 500;
    margin-top: 0.5em;
    &:first-child {
        margin-top: 0;
    }
    a {
        font-weight: 400;
    }
    max-width: calc(100% - 50px);
`;

const RatingSpan = styled.span`
    position: absolute;
    display: inline-block;
    & > * {
        display: inline;
    }
`;

const StarRatingDiv = styled.div`
    padding-left: 0.3em;
`;

// noinspection CssUnknownTarget
const Comment = styled.div`
    display: inline-block;
    font-weight: 500;
    background-color: white;
    border-radius: 8px 8px 8px 12px;
    padding: 12px;
    position: relative;
    filter: drop-shadow(0 0 0.5px #e8e8e8) drop-shadow(0 0 0.5px #e8e8e8) drop-shadow(0 0 0.5px #e8e8e8)
        drop-shadow(0 0 0.75px #e8e8e8);

    ::after {
        content: '';
        background-image: url('../../../../../../../speech-bubble.png');
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: -9px;
        width: 14px;
        height: 20px;
        background-size: 14px 20px;
        display: block;
    }

    @media only screen and (max-width: 500px) {
        padding: 6px;
        ::after {
            left: -5.5px;
            width: 9px;
            height: 10px;
            background-size: 9px 10px;
        }
    }
`;

const ReviewItem = ({ review, doNotShowStarRating, doNotShowHeader }: ReviewItemProps): React.ReactElement => {
    const { starRating, comment, followUp, data } = review;

    //////////////////////////////////////////////
    if (!comment && !starRating && !data?.choice) return null;
    // ^ There are some seeded reviews that don't have a rating or comment.
    //   There's no feedback in that review, it shouldn't exist / needs to be removed from seeding
    if (starRating === 0) return null;
    // ^ Reviews with a rating of 0 shouldn't exist but do.
    //   It's impossible to give a zero on peach.
    //////////////////////////////////////////////

    return (
        <React.Fragment>
            {!doNotShowHeader && (
                <Header>
                    <SubjectTitle subject={getSubject(review)} />
                    {starRating && !doNotShowStarRating && (
                        <RatingSpan>
                            <StarRatingDiv>{`• ${starRating}`}</StarRatingDiv>
                            <YellowStar />
                        </RatingSpan>
                    )}
                    {data?.choice && (
                        <RatingSpan>
                            <StarRatingDiv>{`•`}</StarRatingDiv>
                            <ChoiceDiv>{data.choice}</ChoiceDiv>
                        </RatingSpan>
                    )}
                </Header>
            )}
            {followUp && <FollowUpComponent followUp={followUp} />}
            {comment && <Comment>{comment}</Comment>}
        </React.Fragment>
    );
};

const ChoiceDiv = styled.div`
    margin-left: 0.3em;
    padding: 0.2em 0.4em;
    background-color: #f8f8f8;
    border: solid 1px #d9d9d9;
    box-shadow: 0 0.1em #d9d9d9;
    border-radius: 4px;
`;

const FollowUpDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.3em;
    margin: 0.4em 0 0.8em;
    :first-child {
        margin-top: 0;
    }
`;

const FollowUpBadge = styled.div<{ positive: boolean }>`
    border-radius: 99px;
    padding: 0.2em 0.7em;
    background-color: ${({ positive }) => (positive ? '#2ABD73' : '#E64654')};
    color: white;
    font-weight: 500;
    font-size: 80%;
`;

const FollowUpComponent = ({ followUp: { responses, positive } }: { followUp: FollowUp }) => (
    <FollowUpDiv>
        {responses.map((x) => (
            <FollowUpBadge key={x} positive={positive}>
                {x}
            </FollowUpBadge>
        ))}
    </FollowUpDiv>
);

export default ReviewItem;
