import { computed, Computed, State } from 'easy-peasy';
import { ModelInterface } from './index';
import { SessionResponse } from '../helpers/blueberryClient';
import { groupBy, map } from 'lodash-es';

export interface Guest {
    email: string;
    sessions: SessionResponse[];
}

export interface GuestsModel {
    guests: Computed<GuestsModel, Guest[]>;
}

export const guestsModel: GuestsModel = {
    guests: computed([(_, storeState: State<ModelInterface>) => storeState.sessionModel.sessions], (sessions) => {
        const sessionsWithContactableEmail = sessions.filter((x) => x.Reviewer?.contactable && x.Reviewer?.email);
        return map(
            groupBy(sessionsWithContactableEmail, (x) => x.Reviewer.email.toLowerCase().trim()),
            (v, k) => ({ email: k, sessions: v })
        );
    }),
};
