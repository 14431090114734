declare global {
    interface Window {
        heap: any;
    }
}

export interface HeapType {
    load: (appId?: string) => void;
    track: (event: string, properties: any) => void;
    identify: (id: string) => void;
    resetIdentity: () => void;
    addEventProperties: (properties: any) => void;
}

export const Heap: HeapType = {
    load: (appId = process.env.REACT_APP_HEAP_APP_ID) => window?.heap?.load(appId),
    track: (event, properties = {}) => window?.heap?.track(event, properties),
    identify: (id) => window?.heap?.identify(id),
    resetIdentity: () => window?.heap?.resetIdentity(),
    addEventProperties: (properties) => window?.heap?.addEventProperties(properties),
};
