import { Action, action, Thunk } from 'easy-peasy';
import { ModelInterface } from '.';
import { blueberryClient, Subscription } from '../helpers/blueberryClient';
import { NotifyNetworkError } from '../helpers/NotifyNetworkError';
import { debouncedThunk } from '../helpers/debouncedThunk';

type SubscriptionState = { loaded: false } | { loaded: true; subscription: Subscription };

export type SubscriptionModel = SubscriptionState & {
    _setLoadedSubscription: Action<SubscriptionModel, Subscription>;
    loadSubscription: Thunk<SubscriptionModel, void, any, ModelInterface>;
};

export const subscription: SubscriptionModel = {
    loaded: false,
    _setLoadedSubscription: action((state, payload) => {
        state.loaded = true;
        (state as any).subscription = payload;
    }),
    loadSubscription: debouncedThunk(async (actions) => {
        try {
            const subscription = await blueberryClient.getSubscription();
            actions._setLoadedSubscription(subscription);
        } catch (e) {
            NotifyNetworkError('Error fetching subscription.');
            console.error('loadSubscription error:', e);
        }
    }),
};
