import React, { useEffect } from 'react';
import { useKetchupMustardSea } from '../../customHooks/useKetchupMustardSea';
import { VerticalCenteredGrazeSpinner } from '../brand/VerticalCenteredGrazeSpinner';
import { EditSurveysProvider, useEditSurveys } from './EditSurveysContext';
import { CreateYourFirstSurvey } from './CreateYourFirstSurvey';
import { EditMenu } from './EditMenu';
import { Route, Switch, useHistory } from 'react-router-dom';
import { EditSurvey } from './EditSurvey';
import { PreviewSurvey } from './PreviewSurvey';

export const EditSurveyRouter = () => {
    useKetchupMustardSea();

    return (
        <>
            <EditSurveysProvider>
                <EditSurveyRouterImpl />
            </EditSurveysProvider>
        </>
    );
};

const EditSurveyRouterImpl = () => {
    const { loaded } = useEditSurveys();

    if (!loaded) return <VerticalCenteredGrazeSpinner />;
    return (
        <Switch>
            <Route path="/surveys">
                <Surveys />
            </Route>
            <Route path="/survey/:surveyId/preview">
                <PreviewSurvey />
            </Route>
            <Route path="/survey/:surveyId">
                <EditSurvey />
            </Route>
            <Route path="/menu/:menuId">
                <EditMenu />
            </Route>
        </Switch>
    );
};

const Surveys = () => {
    const { surveys } = useEditSurveys();

    // Eventually there should be a dropdown for picking the current survey
    const history = useHistory();
    useEffect(() => {
        if (surveys.surveys.length === 0) return;
        const defaultSurveyIndex = surveys.surveys.findIndex(({ name }) => name.toLowerCase().includes('din'));
        const defaultSurvey = surveys.surveys[defaultSurveyIndex === -1 ? 0 : defaultSurveyIndex];
        history.replace(`/survey/${defaultSurvey.id}`);
    }, [surveys.surveys, history]);

    if (surveys.surveys.length === 0) return <CreateYourFirstSurvey />;
    return null;
};
