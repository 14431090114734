import { Flavor, RatingFlavor, RatingWithFollowUpByStarFlavor } from '../../types/blueberryTypes';
import { RatingInput, ReviewRating } from './RatingInput';
import { CommentInput, ReviewComment } from './CommentInput';
import { TextAreaRef } from 'antd/es/input/TextArea';
import { FollowUpInput } from './FollowUpInput';
import { RatingWithFollowUpByStarReviewData, ReviewInputPropsBase } from './ReviewInput';
import React, { useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';

interface RatingCommentInputProps extends ReviewInputPropsBase<null | RatingWithFollowUpByStarReviewData> {
    placeholder?: string;
    flavor: RatingFlavor | RatingWithFollowUpByStarFlavor;
}

export const RatingCommentInput = ({
    onChange,
    defaultValue,
    flavor,
    placeholder: defaultPlaceholder,
}: RatingCommentInputProps) => {
    const [rating, setRating] = useState<ReviewRating>(defaultValue?.rating ?? null);
    const [comment, setComment] = useState<ReviewComment>(defaultValue?.comment ?? null);
    const [data, setData] = useState<RatingWithFollowUpByStarReviewData | null>(defaultValue?.data ?? null);
    const [placeholder, setPlaceholder] = useState<string | undefined>(undefined);
    const textAreaRef = useRef<TextAreaRef>();

    useUpdateEffect(() => {
        onChange({ rating, comment, data });
    }, [rating, comment, JSON.stringify(data)]);
    useEffect(() => {
        setPlaceholder(placeholders[rating ?? 0] ?? defaultPlaceholder);
    }, [rating]);

    const setRatingImpl: typeof setRating = (x) => {
        if (x && flavor.flavor === Flavor.Rating) textAreaRef.current?.focus();
        setRating(x);
    };

    return (
        <>
            <RatingInput value={rating} onChange={setRatingImpl} />
            {flavor.flavor === Flavor.RatingWithFollowUpByStar && (
                <FollowUpInput
                    value={data}
                    onChange={setData}
                    flavorData={flavor.flavorData.followUpByStar}
                    starRating={rating}
                />
            )}
            <CommentInput
                innerRef={textAreaRef as any}
                value={comment}
                onChange={setComment}
                placeholder={placeholder}
            />
        </>
    );
};
const placeholders = [
    undefined,
    'What made this horrible?',
    'What made this bad?',
    'What made this OK?',
    'How can we improve this?',
    'What made you love it?',
];
