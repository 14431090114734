import React from 'react';
import styled from 'styled-components';

const Header = styled.header`
    display: flex;
    align-items: center;
    & > * {
        margin-bottom: 0;
    }
    & > h1 {
        font-size: 186%;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.65);
    }
    & > h1 > span {
        margin: 0 0.225em;
    }
`;
interface Props {
    title: string;
}

export const SubjectHeader = ({ title }: Props) => {
    return (
        <Header>
            <h1>{title.trim()}</h1>
        </Header>
    );
};
