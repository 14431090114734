import { useStoreActions, useStoreState } from '../model';
import { useThunk } from './useThunk';

export const useHydrateMenuItems = () => {
    const hydrateMenuItems = useStoreActions((actions) => actions.menuItemsModel.hydrateMenuItems);
    const menuItemsAdded = useStoreState((x) => x.menuItemsModel.menuItemsAdded);

    useThunk(hydrateMenuItems);

    return { menuItemsHydrated: menuItemsAdded };
};
