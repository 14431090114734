import { useEditSurveys, useLoadSurveysCallback } from './EditSurveysContext';
import { blueberryClient } from '../../helpers/blueberryClient';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { ask } from './ask';
import { useMenuItemsForSection } from './EditMenu';

const HideButton = ({ disabledMessage, onOkPromiseFn, title, content, size }) => {
    const onClick = () => ask({ title: `Hide ${title}?`, content, onOkPromiseFn });

    const button = (
        <Button shape="round" size={size} disabled={!!disabledMessage} onClick={onClick}>
            Hide
        </Button>
    );

    if (!!disabledMessage) return <Tooltip title={disabledMessage}>{button}</Tooltip>;
    return button;
};

export const HideMenuSectionButton = ({ title, id }) => {
    const menuItems = useMenuItemsForSection(id);
    const hideMenuSection = useLoadSurveysCallback(() => blueberryClient.hideMenuSection(id), [id]);

    const disabledMessage = menuItems.some((x) => x.active)
        ? 'All Menu Items in a Section must be hidden before hiding a Section.'
        : null;

    return (
        <HideButton
            title={title}
            content={null}
            disabledMessage={disabledMessage}
            onOkPromiseFn={hideMenuSection}
            size="small"
        />
    );
};

export const HidePromptButton = ({ title, id, surveyId }) => {
    const prompts = useEditSurveys().surveys.prompts;
    const hideMenuSection = useLoadSurveysCallback(() => blueberryClient.hidePrompt(id), [id]);

    const disabledMessage =
        prompts.filter((x) => x.active).filter((x) => x.surveyId === surveyId).length < 2
            ? 'Cannot hide the only Question in a Survey.'
            : null;

    return (
        <HideButton
            title={title}
            content={
                <>
                    This will remove {title} from the survey. No existing feedback about {title} will be lost.
                </>
            }
            disabledMessage={disabledMessage}
            onOkPromiseFn={hideMenuSection}
            size="large"
        />
    );
};
