import { ReactComponent as LoadingIndicatorSvg } from '../../svg/loadingIndicator.svg';
import styled, { keyframes } from 'styled-components';
import React from 'react';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const GrazeSpinnerSvg = styled(LoadingIndicatorSvg)`
    display: block;
    width: 160px;
    margin: 0 auto;
    animation: ${rotate} 2s linear infinite;
`;

export const GrazeSpinner = (props: any) => (
    <div {...props}>
        <GrazeSpinnerSvg />
    </div>
);
