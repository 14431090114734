import { ReviewInput, ReviewInputProps } from './ReviewInput';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Div = styled.div`
    width: 90%;
    & > h2 {
        margin-bottom: 0;
        font-weight: 400;
        font-size: 16px;
    }
    & > *:nth-child(2) {
        margin-bottom: 0.5em;
    }
    margin: 0 auto 3em;
`;

type Props = { label: ReactNode } & ReviewInputProps;

export const LabeledReviewInput = ({ label, ...props }: Props) => (
    <Div>
        {typeof label === 'string' ? <h2>{label}</h2> : label}
        <ReviewInput {...props} />
    </Div>
);
