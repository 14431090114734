import styled from 'styled-components';
import { OverviewTimeRangeSelect } from './OverviewTimeRangeSelect';
import React from 'react';

export const Heading = styled.div`
    & > * {
        margin-bottom: 0;
    }
    h1,
    h2 {
        float: left;
    }
    &::after {
        content: '';
        clear: both;
        display: table;
    }
`;

export const HeadingWithOverviewTimeRangeSelect = ({ children, ...rest }) => (
    <Heading {...rest}>
        <h1>{children}</h1>
        <OverviewTimeRangeSelect />
    </Heading>
);
