import { Input, Modal } from 'antd';
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useAsyncEffect } from '../../customHooks/useAsyncEffect';
import { blueberryClient } from '../../helpers/blueberryClient';
import { GrazeSpinner } from '../brand/GrazeSpinner';
import { AnimatedQuestionCard, QuestionCardOverlay } from './QuestionCard';
import { GrazeCard } from '../brand/GrazeCard';
import { LabeledReviewInput } from '@orchard/peach';
import { TextInputWithSubmit } from '../brand/TextInputWithSubmit';
import { GrowTextInput } from '../GrowTextInput';

type ExecuteAndLoadSurveys = (fn: () => Promise<unknown>) => Promise<unknown>;

export const manipulateQuestionGallery = ({ surveyId }, fn: ExecuteAndLoadSurveys) => {
    const modal = Modal.confirm({
        title: <h1>Question Gallery</h1>,
        width: '90vw',
        style: { maxWidth: '900px' },
        okButtonProps: { style: { display: 'none' } },
        icon: null,
    });

    const fn2 = async (myFn) => {
        modal.update({
            title: null,
            content: <GrazeSpinner />,
            cancelButtonProps: { style: { display: 'none' } },
            style: { backgroundColor: 'none', maxWidth: '900px' },
        });
        await fn(myFn);
        modal.destroy();
    };

    modal.update({
        content: <QuestionGallery fn={fn2} surveyId={surveyId} />,
    });
};

const Div = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    margin-bottom: 12px;
`;

const DarkQuestionCardOverlay = styled(QuestionCardOverlay)`
    background-color: #8080802c;
`;

const QuestionGallery = ({ fn, surveyId }: { fn: ExecuteAndLoadSurveys; surveyId }) => {
    const [gallery, setGallery] = useState(null);
    useAsyncEffect(async () => {
        const gallery = await blueberryClient.getQuestionGallery({ surveyId });
        setGallery(gallery);
    }, [surveyId]);

    if (!gallery) return <GrazeSpinner />;

    return (
        <>
            {gallery.length > 0 && (
                <>
                    <h2>Ratings & Reasons</h2>
                    <RatingsAndReasons {...{ gallery, fn, surveyId }} />
                </>
            )}
            <h2>Custom</h2>
            <Div>
                <AddCustomQuestion {...{ fn, surveyId }} />
                <AddMultipleChoiceQuestion {...{ fn, surveyId }} />
            </Div>
        </>
    );
};

const RatingsAndReasons = ({ fn, surveyId, gallery }: { fn: ExecuteAndLoadSurveys; surveyId; gallery: any[] }) => {
    return (
        <Div>
            {gallery.map((x) => (
                <RatingsAndReasonsOption key={x.id} prompt={x} fn={fn} surveyId={surveyId} />
            ))}
        </Div>
    );
};

const RatingsAndReasonsOption = ({ prompt, fn, surveyId }) => {
    const onClick = (ev) => {
        if (ev.target.tagName.toLowerCase() !== 'div') return;
        setTimeout(async () => {
            await fn(() => blueberryClient.createPromptFromPrefab({ surveyId, prefabId: prompt.id }));
        });
    };
    return (
        <AnimatedQuestionCard prompt={prompt} onClick={onClick}>
            <DarkQuestionCardOverlay />
        </AnimatedQuestionCard>
    );
};

const CustomQuestionGrazeCard = styled(GrazeCard)`
    cursor: text;

    img,
    textarea {
        pointer-events: none;
    }

    div {
        margin: 0;
    }
    > div {
        margin: 0 auto;
        width: 100%;
    }
`;

const MarginTextInputWithSubmit = styled(TextInputWithSubmit)`
    margin-bottom: 0.4em !important;
`;

const StyledTextInputWithSubmit = styled(MarginTextInputWithSubmit)`
    input {
        border: none;
    }
    input + button {
        opacity: 0;
    }
    input:focus + button {
        opacity: 100%;
    }
`;

const AddCustomQuestion = ({ fn, surveyId }) => {
    const onSubmit = (title) => fn(() => blueberryClient.createPrompt({ surveyId, title }));
    const ref = useRef<Input>();

    return (
        <CustomQuestionGrazeCard onClick={() => ref.current.input.focus()}>
            <LabeledReviewInput
                label={
                    <StyledTextInputWithSubmit placeholder="Your question here..." onSubmit={onSubmit} inputRef={ref} />
                }
                flavor={{ flavor: 'rating' } as any}
                onChange={() => {}}
                placeholder="Tell us more..."
            />
        </CustomQuestionGrazeCard>
    );
};

const AddChoiceButton = styled.div`
    padding-top: 0.225em;
    text-decoration: underline;
    cursor: pointer;
`;

const AddMultipleChoiceQuestion = ({ fn, surveyId }) => {
    const ref = useRef<Input>();
    const [choices, setChoices] = useState(['', ''] as string[]);
    const onSubmit = (title) => fn(() => blueberryClient.createMultipleChoicePrompt({ surveyId, title, choices }));
    function setChoice(i, text) {
        const array = [...choices];
        array[i] = text;
        setChoices(array);
    }
    function addChoice() {
        const array = [...choices];
        array.push('');
        setChoices(array);
    }

    const submitDisabled = choices.some((x) => !x) || choices.length < 2;

    return (
        <GrazeCard>
            <MarginTextInputWithSubmit
                placeholder="Your multiple-choice question here..."
                onSubmit={onSubmit}
                inputRef={ref}
                disabled={submitDisabled}
            />
            <ChoicesDiv>
                {choices.map((x, i) => (
                    <GrowTextInput
                        key={i}
                        placeholder={`Choice ${i + 1}...`}
                        onChange={({ target }) => setChoice(i, (target as any).value)}
                    />
                ))}
                <AddChoiceButton onClick={addChoice}>add choice</AddChoiceButton>
            </ChoicesDiv>
        </GrazeCard>
    );
};

const ChoicesDiv = styled.div`
    display: flex;
    gap: 0.3em;
    row-gap: 0.9em;
    flex-wrap: wrap;

    input {
        border-radius: 4px;
    }
`;
