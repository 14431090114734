import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

export const GreenButton = ({ children, ...props }) => (
    <StyledButton type="primary" {...props}>
        {children}
    </StyledButton>
);

const StyledButton = styled(Button)`
    filter: hue-rotate(175deg) saturate(250%);
`;
