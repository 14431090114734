import { Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ImperativeModal, showLoadingSpinner } from './ask';
import * as yup from 'yup';
import { blueberryClient } from '../../helpers/blueberryClient';
import { MenuItem } from '../../model/menu';

export const editMenuItemSpelling = (menuItem: MenuItem, afterPromiseFn: () => Promise<unknown>) => {
    const modal = Modal.confirm({
        title: <h1>Edit Spelling</h1>,
        okButtonProps: { disabled: true },
        okText: 'Update',
        width: 600,
        icon: null,
    });
    modal.update({
        content: <EditMenuItemSpelling modal={modal} menuItem={menuItem} afterPromiseFn={afterPromiseFn} />,
    });
};

const Div = styled.div`
    > * {
        margin-bottom: 10px;
    }
    > *:last-child {
        margin-bottom: 0;
    }
`;

const EditMenuItemSpelling = ({
    modal,
    menuItem,
    afterPromiseFn,
}: {
    modal: ImperativeModal;
    menuItem: MenuItem;
    afterPromiseFn: () => Promise<unknown>;
}) => {
    const [newTitle, setNewTitle] = useState(menuItem.title);

    useEffect(
        () =>
            modal.update({
                okButtonProps: { disabled: !yup.string().required().isValidSync(newTitle) },
                onOk: async () => {
                    showLoadingSpinner(modal);
                    await blueberryClient.changeMenuItemSpelling(menuItem.id, newTitle);
                    await afterPromiseFn();
                },
            }),
        [newTitle, modal, menuItem.id]
    );
    return (
        <Div>
            <h2>{menuItem.title} will now be called...</h2>
            <Input
                size="large"
                onChange={(x) => setNewTitle(x.target.value)}
                autoFocus
                placeholder="Menu Item name"
                defaultValue={newTitle}
            />
        </Div>
    );
};
