import styled from 'styled-components';
import { CommentCard } from './card/CommentCard';
import React, { useRef } from 'react';
import { ExpandableCommentCard } from './card/ExpandableCommentCard';
import { GrazePagination } from './brand/GrazePagination';
import { ReviewToSession } from '../model/reviews';
import { NoData } from './brand/NoData';

const Div = styled.div`
    & > * {
        width: 100%;
        margin: 10px 0;
    }
`;

const NoCommentsP = styled(NoData)`
    margin-top: 3em;
`;

interface Props {
    reviews: ReviewToSession[];
    CommentComponent: typeof CommentCard;
}

export const CommentsList = ({ reviews, CommentComponent }: Props) => {
    const topmostElement = useRef<any>(null);
    const reviewsWithComments = reviews.filter((x) => x.comment);
    if (reviewsWithComments.length === 0) return <NoCommentsP>No comments yet!</NoCommentsP>;
    return (
        <Div ref={topmostElement}>
            <GrazePagination items={reviewsWithComments} defaultPage={1} itemsPerPage={25} scrollTo={topmostElement}>
                {({ items }) => (
                    <>
                        {items.map((x) => (
                            <ExpandableCommentCard key={x.id} review={x} CommentComponent={CommentComponent} />
                        ))}
                    </>
                )}
            </GrazePagination>
        </Div>
    );
};
