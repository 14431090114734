import { AddIncentiveButton } from './AddIncentiveButton';
import React from 'react';
import { useCurrentSurvey } from './EditSurvey';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { truncate } from 'lodash-es';
import { useLoadSurveysCallback } from './EditSurveysContext';
import { blueberryClient } from '../../helpers/blueberryClient';
import { ask } from './ask';
import { manipulateIncentive } from './manipulateIncentive';

export const CurrentSurveyIncentive = () => {
    const { incentive } = useCurrentSurvey();
    if (!incentive) return <AddIncentiveButton />;
    return <EditIncentive />;
};

const editIncentive = ({ defaultStatement, defaultPromoCode, defaultExpirationDays, updateIncentive }) =>
    manipulateIncentive(
        {
            title: 'Edit incentive',
            okText: 'Save',
            defaultStatement,
            defaultPromoCode,
            defaultExpirationDays,
        },
        updateIncentive
    );

const EditIncentive = () => {
    const {
        incentive: { id: incentiveId, statement, promoCode, expirationDays },
        id: surveyId,
    } = useCurrentSurvey();

    const removeIncentiveFromSurvey = useLoadSurveysCallback(
        () => blueberryClient.removeIncentiveFromSurvey({ incentiveId, surveyId }),
        [incentiveId, surveyId]
    );

    const updateIncentive = useLoadSurveysCallback(
        ({ statement, promoCode, expirationDays }) =>
            blueberryClient.updateIncentive({ incentiveId, statement, promoCode, expirationDays }),
        [incentiveId]
    );

    const onRemoveActionClick = () => {
        ask({
            title: `Hide ${statement}?`,
            content: <>This will remove {statement} from the survey.</>,
            onOkPromiseFn: removeIncentiveFromSurvey,
        });
    };

    const onEditActionClick = () =>
        editIncentive({
            defaultStatement: statement,
            defaultPromoCode: promoCode,
            defaultExpirationDays: expirationDays,
            updateIncentive,
        });

    const menu = (
        <Menu>
            <Menu.Item key="edit" onClick={onEditActionClick}>
                Edit
            </Menu.Item>
            <Menu.Item key="remove" onClick={onRemoveActionClick}>
                Remove
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Dropdown overlay={menu}>
                <Button size="large">
                    {truncate(statement)}
                    <EllipsisOutlined />
                </Button>
            </Dropdown>
        </>
    );
};
