import React, { useState } from 'react';
import { Button } from 'antd';
import { DownloadOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { toDataURL } from 'qrcode';
import { downloadFile } from '../../helpers/downloadFile';
import { useCurrentSurvey } from './EditSurvey';
import { kebabCase } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { peachUrl } from '../../helpers/peachUrl';
import { useAsyncEffect } from '../../customHooks/useAsyncEffect';
import styled from 'styled-components';

export const useCurrentSurveyUrl = () => {
    const { uuid } = useCurrentSurvey();
    return `${peachUrl}/survey/${uuid}`;
};

export const CurrentSurveyUrl = () => {
    const { id } = useCurrentSurvey();
    const history = useHistory();

    return (
        <>
            <h2>QR Code</h2>
            <CurrentSurveyQrCode />
            <h2>Preview</h2>
            <Button onClick={() => history.push(`/survey/${id}/preview`)} size="large">
                <PlayCircleOutlined /> Go to survey
            </Button>
        </>
    );
};

const QrCodeImg = styled.img`
    padding: 0;
    border-radius: 8px;
    border: #d9d9d9 solid 1px;
    display: block;
    margin-bottom: 12px;
`;

const CurrentSurveyQrCode = () => {
    const { name } = useCurrentSurvey();
    const url = useCurrentSurveyUrl();
    const [dataUrl, setDataUrl] = useState<string | null>(null);

    useAsyncEffect(async () => setDataUrl(await toDataURL(url, { scale: 16 })), [url]);

    const onDownloadQrCode = async () => {
        if (dataUrl) downloadFile(`${kebabCase(name)}-survey-qr-code.png`, dataUrl);
    };

    return (
        <>
            {dataUrl && <QrCodeImg width={256} src={dataUrl} />}
            <Button disabled={!dataUrl} onClick={onDownloadQrCode} size="large">
                <DownloadOutlined /> Download QR Code
            </Button>
        </>
    );
};
