import { useHydrateSessions } from '../customHooks/useHydrateSessions';
import { VerticalCenteredGrazeSpinner } from './brand/VerticalCenteredGrazeSpinner';
import React, { useRef } from 'react';
import { useKetchupMustardSea } from '../customHooks/useKetchupMustardSea';
import { useHistory } from 'react-router-dom';
import { useStoreState } from '../model';
import styled from 'styled-components';
import { NoData } from './brand/NoData';
import { GrazePagination } from './brand/GrazePagination';
import { List, Tag } from 'antd';
import { Guest } from '../model/guests';
import { GrazeLayout } from './brand/GrazeLayout';
import { linkCss } from './brand/GrazeCard';
import { CommentsCount } from './CommentsCount';
import { countComments, getAverageRating } from '../model/reviews';
import { AverageRating } from './AverageRating';
import { GuestSortSelect, useGuestSortOption } from './GuestSortSelect';
import { sortBy } from 'lodash-es';
import { Heading } from './home/HeadingWithOverviewTimeRangeSelect';

const StyledHeading = styled(Heading)`
    margin-bottom: 0.5em;
`;

export const GuestEmailsPage = () => {
    useKetchupMustardSea();
    const { sessionsHydrated } = useHydrateSessions();
    const h1Ref = useRef<any>(null);

    if (!sessionsHydrated) return <VerticalCenteredGrazeSpinner />;

    return (
        <GrazeLayout>
            <StyledHeading>
                <h1 ref={h1Ref}>Guest Emails</h1>
                <GuestSortSelect />
            </StyledHeading>
            <GuestEmails scrollTo={h1Ref} />
        </GrazeLayout>
    );
};

const StyledList = styled(List)`
    overflow: hidden;
    background-color: white;
    margin-bottom: 1em;
    border-radius: 8px;
    box-shadow: #04000010 0 4px 5px;
`;

const StyledListItem = styled(List.Item)`
    display: flex;
    justify-content: space-between;
    & > *:nth-child(2) > * {
        display: inline;
        margin-left: 0.825em;
    }
    ${linkCss}
`;

const useSortedGuests = () => {
    const guests = useStoreState((x) => x.guestsModel.guests);
    const [guestSortOption] = useGuestSortOption();

    switch (guestSortOption) {
        case 'most-review-sessions-first':
            return sortBy(guests, (x) => x.sessions.length).reverse();
        case 'latest-review-first':
            return sortBy(guests, (x) => Math.max(...x.sessions.map((y) => y.updatedAt.valueOf()))).reverse();
        case 'highest-average-rating':
            return sortBy(guests, (x) => {
                const averageRating = getAverageRating(x.sessions.flatMap((y) => y.Reviews));
                return isNaN(averageRating) ? 0 : averageRating;
            }).reverse();
        case 'lowest-average-rating':
            return sortBy(guests, (x) => {
                const averageRating = getAverageRating(x.sessions.flatMap((y) => y.Reviews));
                return isNaN(averageRating) ? Number.MAX_SAFE_INTEGER : averageRating;
            });
    }
};

const GuestEmails = ({ scrollTo }: { scrollTo: React.MutableRefObject<HTMLElement> }) => {
    const guests = useSortedGuests();
    const { push } = useHistory();

    if (guests.length === 0) return <NoEmails>No emails yet!</NoEmails>;

    return (
        <GrazePagination items={guests} defaultPage={1} itemsPerPage={50} scrollTo={scrollTo}>
            {({ items }) => (
                <StyledList
                    size="small"
                    bordered
                    dataSource={items}
                    renderItem={(item: Guest) => (
                        <StyledListItem onClick={() => push(`/guest/${item.email}`)}>
                            <GuestItem {...item} />
                        </StyledListItem>
                    )}
                />
            )}
        </GrazePagination>
    );
};

const StyledTag = styled(Tag)`
    margin-left: 8px;
`;

const GuestItem = ({ email, sessions }: Guest) => {
    const reviews = sessions.flatMap((x) => x.Reviews);
    return (
        <>
            <span>
                {email}
                {sessions.length > 1 && <StyledTag color="green">{sessions.length} reviews</StyledTag>}
            </span>
            <span>
                <AverageRating value={getAverageRating(reviews)} />
                <CommentsCount commentsCount={countComments(reviews)} />
            </span>
        </>
    );
};

const NoEmails = styled(NoData)`
    margin-top: 3em;
`;
