import { useEditSurveys, useLoadSurveysCallback } from './EditSurveysContext';
import { GrazeCard } from '../brand/GrazeCard';
import { EditPrompts } from './EditPrompts';
import React from 'react';
import styled from 'styled-components';
import { GrazeLayout } from '../brand/GrazeLayout';
import { GrowTextInputClass } from '../GrowTextInput';
import { groupBy, mapValues } from 'lodash-es';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { GrazeSpacer } from '../brand/GrazeSpacer';
import { AddMenuButton } from './AddMenuButton';
import { HeaderWithTag } from './HeaderWithTag';
import { NotFound } from '../NotFound';
import { CurrentSurveyIncentive } from './CurrentSurveyIncentive';
import { blueberryClient } from '../../helpers/blueberryClient';
import { useStoreState } from '../../model';
import { CurrentSurveyUrl } from './CurrentSurveyUrl';

export const EditSurveyLayout = styled(GrazeLayout)`
    button,
    input {
        margin-top: 6px;
        margin-right: 6px;
        margin-bottom: 6px;
    }
    ${GrowTextInputClass} {
        margin-right: 6px;
    }
    section {
        margin-bottom: 24px;
    }
`;

export const useCurrentSurvey = () => {
    const {
        surveys: { menus, prompts, surveys },
        params,
    } = useEditSurveys();
    const currentSurvey = surveys.find((x) => x.id === params.surveyId);
    if (!currentSurvey)
        return {
            found: false,
        };

    const menusById = mapValues(groupBy(menus, 'id'), (x) => x[0]);

    return {
        id: currentSurvey.id,
        uuid: currentSurvey.uuid,
        name: currentSurvey.name,
        found: true,
        menus: currentSurvey.menuIds.map((x) => menusById[x]),
        prompts: prompts.filter((x) => x.active && x.surveyId === currentSurvey.id),
        incentive: currentSurvey.incentive,
    };
};

const Card = styled(GrazeCard)`
    h2 {
        margin-top: 16px;
    }
    h2:first-child {
        margin-top: 0;
    }
`;

export const EditSurvey = () => {
    const { found } = useCurrentSurvey();

    if (!found) return <NotFound />;

    return (
        <EditSurveyLayout>
            <SurveyTitle />
            <Card>
                <h2>Menu</h2>
                <CurrentSurveyMenus />
                <Spacer />
                <h2>Questions</h2>
                <EditPrompts />
                <Spacer />
                <h2>Incentive</h2>
                <CurrentSurveyIncentive />
                <Spacer />
                <CurrentSurveyUrl />
            </Card>
        </EditSurveyLayout>
    );
};

const SurveyTitle = () => {
    const { name, id } = useCurrentSurvey();
    const restaurantId = useStoreState((x) => x.userModel.jwtContents.restaurantId);
    const updateSurveyName = useLoadSurveysCallback(
        (name) => blueberryClient.updateSurvey({ surveyId: id, name }),
        [id]
    );

    return (
        <HeaderWithTag
            title={name}
            tag="Survey"
            color="#009e4e"
            updateTitle={updateSurveyName}
            /* Some early adopter restaurants have QR codes with survey names in them.
            Editing the survey name would break the QR code.
            This is a naive fix so that these restaurants cannot edit their survey names. */
            editable={restaurantId >= 8}
        />
    );
};

const Spacer = styled(GrazeSpacer)`
    margin: 24px 0 16px;
`;

const CurrentSurveyMenus = () => {
    const { menus } = useCurrentSurvey();
    const history = useHistory();

    return (
        <MenusDiv>
            {menus.map(({ id, title }) => (
                <Button key={id} size="large" onClick={() => history.push(`/menu/${id}`)}>
                    {title}
                    <RightOutlined />
                </Button>
            ))}
            {menus.length === 0 && <AddMenuButton />}
        </MenusDiv>
    );
};

const MenusDiv = styled.div`
    > * {
        display: block;
    }
`;
