import { ModalFunc } from 'antd/es/modal/Modal';
import { Modal } from 'antd';

export type ImperativeModal = ReturnType<ModalFunc>;

export const showLoadingSpinner = (modal: ImperativeModal) =>
    modal.update({
        okButtonProps: {
            loading: true,
        },
        cancelButtonProps: {
            disabled: true,
        },
    });

export const ask = ({ title, content, onOkPromiseFn }) => {
    const modal = Modal.confirm({
        title,
        content,
        onOk: async () => {
            showLoadingSpinner(modal);
            await onOkPromiseFn();
        },
    });
};
