import { action, Action } from 'easy-peasy';

interface KeyValue {
    key: string;
    value: any;
}

interface Controls {
    object: object;
    set: Action<Controls, KeyValue>;
}

export const controls: Controls = {
    object: {},
    set: action((state, { key, value }) => {
        state.object[key] = value;
    }),
};
