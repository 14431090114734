import { GrazeCard, GrazeLinkCard } from '../brand/GrazeCard';
import React from 'react';
import styled from 'styled-components';
import { ArrowUpOutlined } from '@ant-design/icons';
import { useStoreState } from '../../model';
import { GrazeSkeleton } from '../brand/GrazeSkeleton';

interface HomeCardProps {
    description: string;
    count: number;
    CardComponent?: React.FunctionComponent;
}

const FeedbackMetricCard = ({ count, description, CardComponent }: HomeCardProps) => {
    const hide = !useStoreState((state) => state.sessionModel.sessionsFullyUpdated);
    if (!CardComponent || hide) CardComponent = GrazeCard;
    return (
        <CardComponent>
            {hide && <GrazeSkeleton paragraph={false} />}
            <strong>{count}</strong>
            <span>{description}</span>
        </CardComponent>
    );
};

export interface FeedbackMetricProps {
    reviewersCount: number;
    starRatingsCount: number;
    commentsCount: number;
    emailsCount: number;
}

const ArrowIcon = styled(ArrowUpOutlined)`
    transform: rotate(45deg);
    margin-left: 0.2em;
`;

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    & > * {
        font-size: 109%;
        flex-grow: 1;
        flex-basis: 0;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: 500px) {
            font-size: 100%;
        }
        margin: 5px;
        position: relative;
    }
    & > * > * {
        margin-bottom: 0;
        font-size: 109%;
    }
    strong {
        font-size: 116%;
        font-weight: 500;
        margin-right: 0.25em;
    }
`;

export const FeedbackMetrics = (props: FeedbackMetricProps) => {
    return (
        <Container>
            <FeedbackMetricCard count={props.reviewersCount} description={'reviewers'} />
            <FeedbackMetricCard count={props.starRatingsCount} description={'ratings'} />
            <FeedbackMetricCard count={props.commentsCount} description={'comments'} />
            <FeedbackMetricCard
                count={props.emailsCount}
                description={'emails'}
                CardComponent={({ children, ...rest }) => (
                    <GrazeLinkCard {...rest} to="/emails">
                        {children}
                        <ArrowIcon />
                    </GrazeLinkCard>
                )}
            />
        </Container>
    );
};
