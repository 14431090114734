import { Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ImperativeModal, showLoadingSpinner } from '../editSurvey/ask';
import * as yup from 'yup';
import { blueberryClient } from '../../helpers/blueberryClient';

export const sendInviteEmail = () => {
    const modal = Modal.confirm({
        title: <h1>Send invite</h1>,
        okButtonProps: { disabled: true },
        okText: 'Send',
        width: 600,
        icon: null,
    });
    modal.update({
        content: <SendInviteEmail modal={modal} />,
    });
};

const Div = styled.div`
    > * {
        margin-bottom: 10px;
    }
    > *:last-child {
        margin-bottom: 0;
    }
`;

const SendInviteEmail = ({ modal }: { modal: ImperativeModal }) => {
    const [email, setEmail] = useState('');

    useEffect(
        () =>
            modal.update({
                okButtonProps: { disabled: !yup.string().email().required().isValidSync(email) },
                onOk: async () => {
                    showLoadingSpinner(modal);
                    await blueberryClient.sendInviteEmail(email);
                },
            }),
        [email, modal]
    );
    return (
        <Div>
            <h2>Email</h2>
            <Input size="large" onChange={(x) => setEmail(x.target.value)} autoFocus />
        </Div>
    );
};
