import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import EdiText from 'react-editext';
import { CheckOutlined, CloseOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';

const StyledEdiText = styled(EdiText)`
    button[editext='edit-button'] {
        border: none;
        margin: 0 0 0 4px;
        background: none !important;
        color: rgba(0, 0, 0, 0.325);
        padding: 0;
        &:hover {
            color: rgba(0, 0, 0, 0.65);
        }
    }
    button[editext='save-button'],
    button[editext='cancel-button'],
    [editext='input'] {
        box-shadow: #04000010 0 4px 5px;
    }
    button[editext='save-button'],
    button[editext='cancel-button'] {
        font-size: 18px;
        border-radius: 16px;
        padding: 0 12px;
        margin: 0 0 0 4px;
    }
    button[editext='save-button'] {
        border: #acacac solid 1px;
        background: #acacac;
        color: white;
        &:hover {
            border-color: #595959;
            background: #595959;
        }
    }
    button[editext='cancel-button'] {
        border: #acacac solid 1px;
        color: #acacac;
        background: white !important;
        &:hover {
            border-color: #595959;
            color: #595959;
        }
    }
    [editext='input'] {
        border-radius: 8px;
        font-weight: inherit;
        padding: 4px 10px;
        margin-right: 0;
    }
`;

const Loading = styled(LoadingOutlined)`
    margin-left: 0.5em;
`;

interface Props {
    value: string;
    setValue(value: string): Promise<unknown>;
    onCancel?(): void;
    autofocus?: boolean;
}

export const EditableText = ({ value, setValue, autofocus, onCancel }: Props) => {
    const [processing, setProcessing] = useState<string | null>(null);
    const isMounted = useRef(true);

    useEffect(
        () => () => {
            isMounted.current = false;
        },
        []
    );

    if (processing)
        return (
            <div>
                {processing}
                <Loading />
            </div>
        );
    return (
        <StyledEdiText
            value={value}
            onCancel={onCancel}
            onSave={(value) =>
                setTimeout(async () => {
                    setProcessing(value);
                    await setValue(value);
                    if (isMounted.current) setProcessing(null);
                })
            }
            editButtonContent={<EditOutlined />}
            saveButtonContent={<CheckOutlined />}
            cancelButtonContent={<CloseOutlined />}
            editing={!!autofocus}
            hideIcons
            submitOnEnter
            submitOnUnfocus
            cancelOnEscape
        />
    );
};
