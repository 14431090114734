import * as React from 'react';
import { Form, Button } from 'antd';

import { NavLink } from 'react-router-dom';
import { FormComponentProps } from 'antd/lib/form';
import { ManageSubscription } from './manageSubscription/ManageSubscription';
import { useKetchupMustardSea } from '../customHooks/useKetchupMustardSea';
import { GrazeLayout } from './brand/GrazeLayout';
import { GrazeCard } from './brand/GrazeCard';
import styled from 'styled-components';
import { UserEmailPreferences } from './UserEmailPreferences';
import { useSubscription } from '../customHooks/useSubscription';
import { VerticalCenteredGrazeSpinner } from './brand/VerticalCenteredGrazeSpinner';

const Card = styled(GrazeCard)`
    h2 {
        padding-top: 20px;
        margin-top: 28px;
        border-top: solid 1px #e8e8e8;
    }
    h2:first-child {
        padding-top: 0;
        margin-top: 0;
        border: none;
    }
`;

const NextH1 = styled.h1`
    margin-top: 1em;
    margin-bottom: 0em;
    line-height: 1.1;
    + p {
        margin-bottom: 1.5em;
    }
`;

const AccountInfo: React.SFC<FormComponentProps> = () => {
    useKetchupMustardSea();
    const subscription = useSubscription();

    if (!subscription) return <VerticalCenteredGrazeSpinner />;

    return (
        <>
            <GrazeLayout>
                <h1>My Account</h1>
                <Card>
                    <h2>Profile</h2>
                    <ChangePasswordButton />
                    <h2>Subscription</h2>
                    <ManageSubscription />
                </Card>
                <NextH1>Email Preferences</NextH1>
                <p>Choose what messages you&apos;d like to receive</p>
                <UserEmailPreferences />
            </GrazeLayout>
        </>
    );
};

const GrayButton = styled(Button)`
    filter: grayscale(100%) brightness(150%);
`;

const ChangePasswordButton = () => (
    <GrayButton type="primary">
        <NavLink to="/changePassword">Change Password</NavLink>
    </GrayButton>
);

export default Form.create()(AccountInfo);
