import React from 'react';
import { VerticalCenteredGrazeSpinner } from './brand/VerticalCenteredGrazeSpinner';
import { NotFound } from './NotFound';
import { useKetchupMustardSea } from '../customHooks/useKetchupMustardSea';
import { useParamsId } from '../customHooks/useParamsId';
import { GrazeLayout } from './brand/GrazeLayout';
import { SubjectHeader } from './SubjectHeader';
import { CommentsList } from './CommentsList';
import { ReviewToSession } from '../model/reviews';
import { CommentCard } from './card/CommentCard';
import { RatingsBreakdown } from './home/RatingsBreakdown';
import styled from 'styled-components';
import { MultipleChoiceBreakdown } from './MultipleChoiceBreakdown';

interface Subject {
    id: number;
    title: string;
    reviews: ReviewToSession[];
    flavor?: string;
}

interface Props {
    loaded: boolean;
    subjects: Subject[];
    CommentComponent?: typeof CommentCard;
}

const StyledRatingsBreakdown = styled(RatingsBreakdown)`
    margin: 12px 0 16px;
`;

const H2 = styled.h2``;

export const SubjectRouter = ({ loaded, subjects, CommentComponent }: Props) => {
    if (!CommentComponent) CommentComponent = CommentCard;

    const id = useParamsId();
    useKetchupMustardSea();

    if (!loaded) return <VerticalCenteredGrazeSpinner />;

    const subject = subjects.find((x) => x.id === id);

    if (!subject) return <NotFound />;

    const isMultipleChoice = 'flavor' in subject && subject.flavor === 'multipleChoice';

    return (
        <GrazeLayout>
            <SubjectHeader title={subject.title} />
            {isMultipleChoice ? (
                <MultipleChoiceBreakdown reviews={subject.reviews} prompt={subject} />
            ) : (
                <>
                    <StyledRatingsBreakdown reviews={subject.reviews} />
                    <H2>Reviews</H2>
                    <CommentsList reviews={subject.reviews} CommentComponent={CommentComponent} />
                </>
            )}
        </GrazeLayout>
    );
};
