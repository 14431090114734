/* eslint-disable react/prop-types */
import * as React from 'react';
import axios from 'axios';
import { Form, Card, Input, Button, Breadcrumb } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { NavLink } from 'react-router-dom';
import getApiUrl from '../helpers/getApiUrl';
import { useStoreState } from '../model/index';
import Notify from '../helpers/Notify';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const ChangePassword: React.SFC<FormComponentProps> = (props) => {
    const username = useStoreState((state) => state.userModel.jwtContents.username);
    const handleSubmit = async (e): Promise<void> => {
        e.preventDefault();

        props.form.validateFields(async (err, values) => {
            const { oldPassword, newPassword, confirmNewPassword } = values;

            if (!err && oldPassword && newPassword && confirmNewPassword) {
                if (oldPassword === newPassword) {
                    props.form.setFields({
                        oldPassword: { value: '', errors: [new Error('New password cannot match the old password!')] },
                        newPassword: { value: '', errors: [new Error('New password cannot match the old password!')] },
                        confirmNewPassword: { value: '' },
                    });
                } else {
                    if (newPassword === confirmNewPassword) {
                        try {
                            await axios.get(
                                `${getApiUrl()}/changePassword?oldpassword=${oldPassword}&newpassword=${newPassword}&confirmpassword=${confirmNewPassword}&username=${username}`
                            );

                            props.form.setFields({
                                oldPassword: { value: '' },
                                newPassword: { value: '' },
                                confirmNewPassword: { value: '' },
                            });
                            Notify('Password Reset Successful', 'Your password has been changed', 'success');
                        } catch (err) {
                            props.form.setFields({
                                oldPassword: { value: '', errors: [new Error('Password not correct!')] },
                                newPassword: { value: '' },
                                confirmNewPassword: { value: '' },
                            });
                        }
                    } else {
                        props.form.setFields({
                            newPassword: { value: '', errors: [new Error('Passwords must match!')] },
                            confirmNewPassword: { value: '', errors: [new Error('Passwords must match!')] },
                        });
                    }
                }
            } else {
                props.form.setFields({
                    oldPassword: { value: '', errors: [new Error('Old password required!')] },
                    newPassword: { value: '', errors: [new Error('New password required!')] },
                    confirmNewPassword: { value: '', errors: [new Error('Confirm password required!')] },
                });
            }
        });
    };
    const { getFieldDecorator } = props.form;

    return (
        <>
            <Card>
                <Breadcrumb style={{ fontSize: '1.5em' }}>
                    <Breadcrumb.Item>
                        <NavLink to="/account"> Account Settings</NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Change Password</Breadcrumb.Item>
                </Breadcrumb>
            </Card>
            <Card>
                <Form {...layout} id="passwordChange" onSubmit={handleSubmit}>
                    <Form.Item label="Old Password">
                        {getFieldDecorator('oldPassword', {
                            rules: [{ message: 'Please input your old password!' }],
                        })(<Input type="password" name="oldPassword" />)}
                    </Form.Item>

                    <Form.Item label="New Password">
                        {getFieldDecorator('newPassword', {
                            rules: [{ message: 'Please input your new password!' }],
                        })(<Input type="password" name="newPassword" />)}
                    </Form.Item>
                    <Form.Item label="Confirm New Password">
                        {getFieldDecorator('confirmNewPassword', {
                            rules: [{ message: 'Please re-enter your new password' }],
                        })(<Input type="password" name="confirmNewPassword" />)}
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button style={{ marginRight: '3%' }} type="primary" htmlType="submit">
                            Save
                        </Button>
                        <Button htmlType="reset" style={{ backgroundColor: '#707070', color: 'white' }}>
                            Cancel
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
};

export default Form.create()(ChangePassword);
