import { Switch } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { GrazeCard } from './brand/GrazeCard';
import { useAsyncEffect } from '../customHooks/useAsyncEffect';
import { blueberryClient } from '../helpers/blueberryClient';

const Div = styled.div`
    display: flex;
    flex-direction: row;

    column-gap: 1em;

    h2 {
        line-height: 1;
    }

    p {
        margin: 0;
    }
`;

const Card = styled(GrazeCard)`
    display: flex;
    flex-direction: column;
    row-gap: 1.3em;
`;

export const UserEmailPreferences = () => {
    const [receiveFeedbackAlerts, setReceiveFeedbackAlerts] = useState(false);
    const [receiveWeeklyUpdates, setReceiveWeeklyUpdates] = useState(false);
    const [loading, setLoading] = useState(true);

    async function refresh() {
        setLoading(true);
        const data = await blueberryClient.getEmailPreferences();
        setReceiveFeedbackAlerts(data.receiveFeedbackAlerts);
        setReceiveWeeklyUpdates(data.receiveWeeklyUpdates);
        setLoading(false);
    }

    useAsyncEffect(refresh, []);

    async function toggle(preferences) {
        setLoading(true);
        await blueberryClient.updateEmailPreferences(preferences);
        await refresh();
    }

    return (
        <Card>
            <Toggle
                checked={receiveWeeklyUpdates}
                loading={loading}
                onClick={() => toggle({ receiveWeeklyUpdates: !receiveWeeklyUpdates })}
            >
                <h2>Weekly Updates</h2>
                <p>Receive an update every Monday about the last week</p>
            </Toggle>
            <Toggle
                checked={receiveFeedbackAlerts}
                loading={loading}
                onClick={() => toggle({ receiveFeedbackAlerts: !receiveFeedbackAlerts })}
            >
                <h2>Less-than-perfect Feedback</h2>
                <p>Receive a notification about all feedback less than 5-stars</p>
            </Toggle>
        </Card>
    );
};

const Toggle = ({ loading, checked, children, onClick }) => {
    return (
        <Div>
            <div>
                <Switch loading={loading} disabled={loading} checked={checked} onClick={onClick} />
            </div>
            <div>{children}</div>
        </Div>
    );
};
