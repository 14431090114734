import { SessionResponse } from '../helpers/blueberryClient';
import { now } from '../helpers/now';
import { useStoreState } from '../model';
import { useOverviewTimeRange } from './useOverviewTimeRange';

const getSessionsForLastDays = (sessions: SessionResponse[], days: number) =>
    sessions.filter((x) => x.updatedAt.diff(now.date, 'days') >= -days);

export const useSessionsInOverviewTimeRange = () => {
    const sessions = useStoreState((state) => state.sessionModel.sessions);
    const [range] = useOverviewTimeRange();
    return range === '30-days' ? getSessionsForLastDays(sessions, 30) : sessions;
};
