import * as React from 'react';
import notFound from '../svg/notFound.svg';
import styled from 'styled-components';
import { useKetchupMustardSea } from '../customHooks/useKetchupMustardSea';

const Div = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, calc(-50% + 45px));

    & > img {
        height: 30vmin;
        margin-bottom: 0.7em;
    }
`;

export const NotFound = () => (
    <Div>
        <img src={notFound} alt="Not Found" />
        <h3>Page Not Found</h3>
        <p>contact@grazeanalytics.com</p>
    </Div>
);

export const NotFoundPage = () => {
    useKetchupMustardSea();
    return <NotFound />;
};
