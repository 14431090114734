import { Input, InputNumber, Modal, Select } from 'antd';
import { ImperativeModal, showLoadingSpinner } from './ask';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const { Option } = Select;

type SubmitIncentive = ({ statement, promoCode, expirationDays }) => Promise<unknown>;

export const manipulateIncentive = (
    { title, okText, defaultPromoCode, defaultStatement, defaultExpirationDays },
    submitIncentive: SubmitIncentive
) => {
    const modal = Modal.confirm({
        title: <h1>{title}</h1>,
        okButtonProps: { disabled: true },
        okText,
        width: 600,
        icon: null,
    });
    modal.update({
        content: (
            <ManipulateIncentive
                modal={modal}
                submitIncentive={submitIncentive}
                defaultPromoCode={defaultPromoCode}
                defaultStatement={defaultStatement}
                defaultExpirationDays={defaultExpirationDays}
            />
        ),
    });
};

const Div = styled.div`
    > * {
        margin-bottom: 10px;
    }
    > *:last-child {
        margin-bottom: 0;
    }
`;

const ManipulateIncentive = ({
    modal,
    submitIncentive,
    defaultPromoCode,
    defaultStatement,
    defaultExpirationDays,
}: {
    modal: ImperativeModal;
    submitIncentive: SubmitIncentive;
    defaultStatement;
    defaultPromoCode;
    defaultExpirationDays;
}) => {
    const [statement, setStatement] = useState(defaultStatement);
    const [promoCode, setPromoCode] = useState(defaultPromoCode);
    const [expirationDays, setExpirationDays] = useState(defaultExpirationDays);
    const [expirationUnits, setExpirationUnits] = useState(
        defaultExpirationDays % 7 === 0 ? defaultExpirationDays / 7 : defaultExpirationDays
    );
    const [unit, setUnit] = useState(defaultExpirationDays % 7 === 0 ? 'week' : 'day');
    useEffect(() => {
        setExpirationDays(unit === 'day' ? expirationUnits : expirationUnits * 7);
    }, [unit, expirationUnits]);

    useEffect(
        () =>
            modal.update({
                okButtonProps: { disabled: promoCode.length === 0 || statement.length === 0 || expirationDays === 0 },
                onOk: async () => {
                    showLoadingSpinner(modal);
                    await submitIncentive({ promoCode, statement, expirationDays });
                },
            }),
        [statement, promoCode, expirationDays, modal, submitIncentive]
    );
    return (
        <Div>
            <h2>Incentive</h2>
            <Input
                size="large"
                placeholder="$5 off next order of $25+"
                defaultValue={statement}
                onChange={(x) => setStatement(x.target.value)}
                autoFocus
            />
            <h2>Promo code</h2>
            <Input
                size="large"
                placeholder="FEEDBACK"
                defaultValue={promoCode}
                onChange={(x) => setPromoCode(x.target.value)}
            />
            <ExpiresAfter>Expires after</ExpiresAfter>
            <StyledInputNumber
                min={1}
                defaultValue={expirationUnits}
                onChange={(x) => setExpirationUnits(x)}
                size="large"
                precision={0}
            />
            <Select defaultValue={unit} onChange={(x) => setUnit(x)} size="large">
                <Option value="day">day{expirationUnits > 1 ? 's' : ''}</Option>
                <Option value="week">week{expirationUnits > 1 ? 's' : ''}</Option>
            </Select>
        </Div>
    );
};

const ExpiresAfter = styled.h2`
    display: inline;
    margin-right: 0.4em;
`;

const StyledInputNumber = styled(InputNumber)`
    width: 3.6em;
    margin-right: 0.4em;
    margin-top: 16px;
`;
