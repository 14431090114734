import React, { HTMLAttributes, useState } from 'react';
import styled from 'styled-components';
import { Pagination } from 'antd';

interface Props<T> {
    items: T[];
    children: (props: { items: T[] }) => React.ReactNode;
    defaultPage: number;
    itemsPerPage: number;
    scrollTo: React.MutableRefObject<HTMLElement>;
}

export function GrazePagination<T>({
    scrollTo,
    children,
    defaultPage,
    items,
    itemsPerPage,
    ...rest
}: Props<T> & HTMLAttributes<{}>) {
    const [currentPage, setCurrentPage] = useState(defaultPage);

    const onChange = (page: number) => {
        setCurrentPage(page);
        scrollTo.current?.scrollIntoView();
    };

    const pagedItems = items.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <>
            {children({ items: pagedItems })}
            {items.length > itemsPerPage && (
                <FeedPagination
                    {...rest}
                    defaultCurrent={defaultPage}
                    total={items.length}
                    pageSize={itemsPerPage}
                    onChange={onChange}
                    showLessItems
                />
            )}
        </>
    );
}

const FeedPagination = styled(Pagination)`
    & .ant-pagination-item-link-icon {
        color: rgba(0, 0, 0, 0.5) !important;
    }
    & .ant-pagination-item,
    & .ant-pagination-next,
    & .ant-pagination-prev {
        box-shadow: #04000010 0 4px 5px;
    }
    text-align: center;
`;
