import { useStoreActions, useStoreState } from '../model';
import { useCallback } from 'react';

export const useControlsState = <T>(key: string, defaultValue: T): [T, (value: T) => void] => {
    const controlsObject = useStoreState((state) => state.controls.object);
    const setControl = useStoreActions((state) => state.controls.set);

    const setControlsState = useCallback(
        (value: T) => {
            setControl({ key, value });
        },
        [key, setControl]
    );

    const value = (controlsObject[key] ?? defaultValue) as T;
    return [value, setControlsState];
};
