import { Input } from 'antd';
import React, { useState } from 'react';
import { GreenButton } from '../brand/GreenButton';
import styled from 'styled-components';
import { GrazeLayout } from '../brand/GrazeLayout';
import { useLoadSurveysCallback } from './EditSurveysContext';
import { blueberryClient } from '../../helpers/blueberryClient';

export const CreateYourFirstSurvey = () => {
    const [surveyName, setSurveyName] = useState('');
    const [creatingSurvey, setCreatingSurvey] = useState(false);
    const createSurvey = useLoadSurveysCallback((name: string) => blueberryClient.createSurvey({ name }), []);

    return (
        <Layout>
            <h1>Create your first Survey</h1>
            <StepsToSuccess />
            <Input
                size="large"
                placeholder="Enter survey name"
                defaultValue={surveyName}
                onChange={(x) => setSurveyName(x.target.value)}
            />
            <GreenButton
                size="large"
                disabled={surveyName.length === 0}
                loading={creatingSurvey}
                onClick={async () => {
                    setCreatingSurvey(true);
                    await createSurvey(surveyName);
                }}
            >
                Get Started
            </GreenButton>
        </Layout>
    );
};

const StepsToSuccess = () => {
    return (
        <OrderedList>
            <ListItem index={1}>Create survey</ListItem>
            <ListItem index={2}>Get QR code</ListItem>
            <ListItem index={3}>Collect feedback</ListItem>
        </OrderedList>
    );
};

const ListItem = ({ index, children }) => {
    return (
        <Li>
            <div>{index}</div>
            <p>{children}</p>
        </Li>
    );
};

const Li = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    div {
        background: #009e4e;
        color: white;
        width: 36px;
        height: 36px;
        font-size: 22px;
        line-height: 1.67em;
        border-radius: 999px;
        margin-bottom: 8px;
    }
    p {
        margin-bottom: 0;
    }
`;

const OrderedList = styled.ol`
    padding: 0;
    display: flex;
`;

const Layout = styled(GrazeLayout)`
    max-width: 600px;
    text-align: center;
    > * {
        margin-top: 0;
        margin-bottom: 30px;
    }
    > *:last-child {
        margin-bottom: 0;
    }
`;
