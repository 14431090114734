import { computed, Computed, State } from 'easy-peasy';
import { ModelInterface } from './index';
import { MenuItem } from './menu';
import { Prompt } from './prompts';
import { SessionResponse } from '../helpers/blueberryClient';
import { meanBy } from 'lodash-es';

export const isMenuItemReview = (test: Review): test is MenuItemReview => 'MenuItem' in test && !!test.MenuItem;
export const isPromptReview = (test: Review): test is PromptReview => 'Prompt' in test && !!test.Prompt;

export const countComments = (reviews: Review[]) => reviews.filter((x) => x.comment).length;

export const getRatings = (reviews: Review[]) => {
    const reviewsWithRatings = reviews.filter((rev) => rev.starRating);
    return {
        ratingsCount: reviewsWithRatings.length,
        averageRating: meanBy(reviewsWithRatings, 'starRating'),
    };
};
export const getAverageRating = (reviews: Review[]) => getRatings(reviews).averageRating;

interface WithSession {
    session: SessionResponse;
}

export type ReviewToSession = Review & WithSession;
export type PromptReviewToSession = PromptReview & WithSession;
export type MenuItemReviewToSession = MenuItemReview & WithSession;

interface ReviewBase {
    id: number;
    starRating: number;
    comment: string;
    reviewType: 'atmosphere' | 'service' | 'food' | 'general';
}

export interface MenuItemReview extends ReviewBase {
    MenuItem: MenuItem;
}

export interface PromptReview extends ReviewBase {
    Prompt: Prompt;
}

export interface FollowUp {
    question: string;
    responses: string[];
    positive: boolean;
}

export type Review = (MenuItemReview | PromptReview) & {
    followUp?: FollowUp;
    data?: Record<string, any>;
};

export interface ReviewsModel {
    reviews: Computed<ReviewsModel, ReviewToSession[]>;
}

const reviewsModel: ReviewsModel = {
    reviews: computed([(_, storeState: State<ModelInterface>) => storeState.sessionModel.sessions], (sessions) =>
        sessions.flatMap((session) => session.Reviews.map((x) => ({ ...x, session })))
    ),
};

export default reviewsModel;
