import { createStore, action } from 'easy-peasy';
import model, { ModelInterface } from './model/index';

export const store = createStore({ ...model, setState: action((state, payload) => payload) });

export type ApricotStore = typeof store;

export const initialState = store.getState();

export const resetStore = (): void => {
    store.dispatch.setState({
        ...initialState,
        userModel: {
            jwtContents: null,
            jwtToken: null,
            isLoggedIn: false,
            restaurants: [],
        },
    });
};

const sampleJWTToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXN0YXVyYW50SWQiOjEsInVzZXJuYW1lIjoiVGVzdFVzZXIiLCJlbWFpbCI6InRlc3RAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJUZXN0IiwibGFzdE5hbWUiOiJVc2VyIiwibGFzdExvZ2luIjoiMjAyMC0wNS0wNFQwNToxNDoyMS4wMDBaIiwiaWF0IjoxNTkwMTMyMjc3LCJleHAiOjE1OTAyMTg2Nzd9.hmOgF9fNHZV9OizVH5pU5DB_UK03p07kyrTXQUC-rXk';
const sampleJWTContents = {
    restaurantId: 1,
    username: 'TestUser',
    email: 'test@test.com',
    firstName: 'Test',
    lastName: 'User',
    lastLogin: '2020-05-04T05:14:21.000Z',
    iat: 1590132277,
    exp: 1590218677,
};

export const initialUserModel = { jwtContents: sampleJWTContents, jwtToken: sampleJWTToken, isLoggedIn: true };

export const storeWithJWT = createStore(model, {
    initialState: { userModel: initialUserModel },
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getStoreWithState = (initialState: Partial<ModelInterface>) => {
    return createStore(model, { initialState });
};

export default store;
