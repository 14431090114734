import React, { useRef } from 'react';
import { SessionCard } from '../card/SessionCard';

import { useStoreState } from '../../model';
import { GrazePagination } from '../brand/GrazePagination';

export const Feed = () => {
    const sessions = useStoreState((state) => state.sessionModel.sessions);
    const topmostElement = useRef<HTMLDivElement>(null);

    return (
        <>
            <h1 ref={topmostElement}>Live Feed</h1>
            <GrazePagination
                data-testid="feedPagination"
                items={sessions}
                defaultPage={1}
                itemsPerPage={25}
                scrollTo={topmostElement}
            >
                {({ items }) => (
                    <>
                        {items.map((session) => (
                            <SessionCard key={session.sessionUUID} session={session} />
                        ))}
                    </>
                )}
            </GrazePagination>
        </>
    );
};
