import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const cardCss = css`
    background-color: white;
    border-radius: 8px;
    box-shadow: #04000010 0 4px 5px;
    border: #e8e8e8 solid 1px;
    padding: 24px;
    @media only screen and (max-width: 500px) {
        padding: 12px;
    }
`;

export const GrazeCard = styled.div`
    ${cardCss}
`;

export const linkCss = css`
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    &:hover,
    &:focus {
        background-color: #fbfbfb;
        color: #ed454d;
    }
    &:not(:hover):not(:active):not(:focus) {
        color: rgba(0, 0, 0, 0.65);
    }
`;

export const GrazeLinkCard = styled(Link)`
    ${cardCss}
    ${linkCss}
`;
