import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';

import { StoreProvider } from 'easy-peasy';
import store from './store';
import { Heap } from './helpers/analytics';
import { startListeningToUserModel } from './helpers/blueberryClient';
import { preloadEmoji, ratingEmojiNames } from '@orchard/peach';

Heap.load();
startListeningToUserModel(store);

setTimeout(() => preloadEmoji(...ratingEmojiNames));

ReactDOM.render(
    <StoreProvider store={store}>
        <App />
    </StoreProvider>,
    document.getElementById('root')
);
