import styled, { css } from 'styled-components';
import React, { ComponentProps, useRef } from 'react';
import { Emoji } from '../Emoji';
import { EmojiName } from '../Emoji/getEmojiUrl';
import { useBurst } from '../../hooks/useBurst';

type GivenReviewRating = 1 | 2 | 3 | 4 | 5;
export type ReviewRating = GivenReviewRating | null;

const RatingOptions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RatingOption = styled(({ selected, anySelected, ...rest }) => <Emoji {...rest} />)<{
    selected: boolean;
    anySelected: boolean;
}>`
    border-radius: 999px;
    padding: 4px;
    transition: transform 0.125s, opacity 0.125s;
    background-color: white;
    border: solid 1px #d9d9d9;

    &:active {
        transform: scale(0.8, 0.8);
    }

    ${(props) =>
        props.selected &&
        css`
            background-color: #e7f5ff;
            box-shadow: #04000020 0 4px 5px;
            transform: scale(1.05, 1.05);
            &:active {
                transform: scale(0.95, 0.95);
            }
        `}
    ${(props) =>
        !props.selected &&
        props.anySelected &&
        css`
            border: none;
            background-color: initial;
            box-shadow: none;
            opacity: 0.3;
        `}
  ${(props) =>
        !props.selected &&
        css`
            transform: scale(0.9, 0.9);
        `}
`;

interface Props {
    value: ReviewRating;
    onChange(rating: ReviewRating): void;
}

export const RatingInput = ({ value, onChange }: Props) => {
    const props = (x: GivenReviewRating) => {
        return {
            selected: value === x,
            anySelected: !!value,
            onClick: () => onChange(value === x ? null : x),
            width: 48,
        };
    };

    return (
        <RatingOptions>
            <RatingOptionImpl name={ratingEmojiNames[0]} {...props(1)} />
            <RatingOptionImpl name={ratingEmojiNames[1]} {...props(2)} />
            <RatingOptionImpl name={ratingEmojiNames[2]} {...props(3)} />
            <RatingOptionImpl name={ratingEmojiNames[3]} {...props(4)} />
            <RatingOptionImpl name={ratingEmojiNames[4]} {...props(5)} />
        </RatingOptions>
    );
};

const RatingOptionImpl = ({ onClick, ...props }: ComponentProps<typeof RatingOption>) => {
    const elemRef = useRef<HTMLElement>();
    const burst = useBurst(elemRef);

    const onClickImpl = () => {
        if (!props.selected) burst('yellowLines');
        onClick();
    };

    return <RatingOption {...props} onClick={onClickImpl} innerRef={elemRef} />;
};

export const ratingEmojiNames: ReadonlyArray<EmojiName> = [
    'confounded-face',
    'slightly-frowning-face',
    'neutral-face',
    'grinning-face',
    'smiling-face-with-heart-eyes',
];
