import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { GrazeCard } from './brand/GrazeCard';
import { PercentText } from './PercentText';
import { FilledBar } from './FilledBar';

const Card = styled(GrazeCard)`
    display: flex;
    flex-direction: column;
    gap: 1.5em;
`;

export function MultipleChoiceBreakdown({ prompt, reviews }) {
    const groupedReviews = _(reviews)
        .groupBy((x) => x.data.choice)
        .sortBy((x) => x.length)
        .value()
        .reverse();

    const choices = _(groupedReviews.map((x) => ({ count: x.length, choice: x[0].data.choice })))
        .concat(...prompt.flavorData.choices.map((x) => ({ count: 0, choice: x })))
        .uniqBy((x) => x.choice)
        .value();

    const total = reviews.length;

    return (
        <>
            <H3>
                {reviews.length} {reviews.length === 1 ? 'person has' : 'people have'} answered this question
            </H3>
            <Card>
                {choices.map((x) => (
                    <Bar key={x.choice} {...x} unit={x.count / total} />
                ))}
            </Card>
        </>
    );
}

const TextDiv = styled.div`
    display: flex;
    justify-content: space-between;

    > div > :nth-child(2),
    > :nth-child(1) {
        font-size: 120%;
        font-weight: 500;
    }

    line-height: 1.6;
`;

const CountDiv = styled.div`
    display: flex;
    > :nth-child(1) {
        position: relative;
        top: 0.33em;
        font-size: 90%;
        opacity: 0.7;
        margin-right: 0.4em;
    }
`;

const BarDiv = styled.div``;

function Bar({ choice, count, unit }) {
    return (
        <BarDiv>
            <TextDiv>
                <div>{choice}</div>
                <CountDiv>
                    <div>
                        {count} {count === 1 ? 'response' : 'responses'}
                    </div>
                    <PercentText unit={unit} />
                </CountDiv>
            </TextDiv>
            <FilledBar unit={unit} />
        </BarDiv>
    );
}

const H3 = styled.h3`
    opacity: 0.7;
`;
