import { YellowStar } from './brand/YellowStar';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { formatRating } from '../helpers/formatRating';

const Span = styled.span`
    letter-spacing: -0.04em;
`;

export const AverageRating = ({ value, ...rest }: { value: number } & HTMLAttributes<{}>) => {
    return (
        <Span {...rest}>
            {formatRating(value)}
            <YellowStar />
        </Span>
    );
};
