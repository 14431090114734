import styled from 'styled-components';
import { MessageOutlined } from '@ant-design/icons';
import React, { HTMLAttributes } from 'react';

const CommentsCountSpan = styled.span`
    letter-spacing: -0.03em;
    & > span {
        margin-left: 0.2em;
    }
`;

type Props = { commentsCount: number } & HTMLAttributes<{}>;

export const CommentsCount = ({ commentsCount, ...rest }: Props) => {
    return (
        <CommentsCountSpan {...rest}>
            {commentsCount}
            <MessageOutlined />
        </CommentsCountSpan>
    );
};
