import styled, { createGlobalStyle } from 'styled-components';
import React, { useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { ReactComponent as LogoSourceSvg } from '../../svg/logo.svg';
import { Link, NavLink } from 'react-router-dom';
import {
    CloseOutlined,
    DownOutlined,
    FileTextOutlined,
    HomeOutlined,
    MenuOutlined,
    SettingOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { useStoreState } from '../../model';
import { GrazeLayout } from './GrazeLayout';
import { Dropdown, Menu } from 'antd';
import { resetStore } from '../../store';
import { RootLayout } from '../RootLayout';
import { useSwitchRestaurant } from '../../customHooks/useSwitchRestaurant';

const Drawer = styled.aside`
    min-width: 360px;
    > div {
        background-color: white;
        width: 360px;
        border-right: #e8e8e8 solid 1px;
        box-shadow: #04000010 0 4px 5px;
        padding: 18px;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        z-index: 10;
        > *:last-child {
            position: absolute;
            bottom: 28px;
        }
    }
    > div > *:last-child {
        position: absolute;
        bottom: 28px;
    }

    a {
        margin-left: 22px;
        margin-top: 6px;
        :first-child {
            margin-top: 20px;
        }
        font-size: 22px;
        padding: 0.4em 0.8em;
        color: black;
        border-radius: 999px;
        text-decoration: none;
        background-color: white;
        :hover {
            background-color: #e0e0e0;
        }
        transition: background-color 0.25s;
        > * {
            margin-right: 0.5em;
        }
    }

    @media only screen and (max-width: 800px) {
        min-width: 270px;
        > div {
            width: 270px;
        }
        > div > *:last-child {
            position: initial;
            margin-top: 4em;
        }
        a {
            font-size: 20px;
        }
    }

    @media only screen and (max-width: 1000px) {
        > div {
            padding: 15px;
        }
        a {
            margin: 0 0 6px !important;
        }
    }
`;

const DesktopGlobalStyle = createGlobalStyle`
  ${GrazeLayout} {
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
  }
  ${RootLayout} {
    display: flex;
  }
`;

const LogoSvg = styled(LogoSourceSvg)`
    width: 34px;
`;

const GrazeName = styled.h1`
    font-size: 150%;
    color: black;
    margin: 0 12px 0;
`;

export const GrazeNavigation = () => {
    const hasHamburgerMenu = useWindowWidth() < 1000;

    if (hasHamburgerMenu) return <MobileGrazeNavbar />;

    return (
        <>
            <DesktopGlobalStyle />
            <NavigationDrawer beforeComponent={<GrazeLogoName />} />
        </>
    );
};

const ShadowBehindDrawer = styled.div`
    position: fixed;
    background-color: #dddddd80;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
`;

const MobileGrazeNavbar = () => {
    const [expanded, setExpanded] = useState(false);
    const close = () => setExpanded(false);
    return (
        <>
            {expanded && <ShadowBehindDrawer onClick={close} />}
            {expanded && <NavigationDrawer beforeComponent={<CloseIcon onClick={close} />} />}
            <MobileGrazeHeader>
                <MenuOutlined onClick={() => setExpanded(!expanded)} />
                <GrazeLogoName />
            </MobileGrazeHeader>
        </>
    );
};

const MobileGrazeHeader = styled.header`
    > *:first-child {
        position: absolute;
        font-size: 30px;
        top: 13.5px;
        left: 6.25vw;
    }
    margin-top: 10px;
    margin-bottom: 6px;
`;

const CloseIcon = styled(CloseOutlined)`
    position: absolute;
    font-size: 30px;
    top: 15px;
    right: 15px;
`;

const NavigationDrawer = ({ beforeComponent }) => (
    <Drawer>
        <div>
            {beforeComponent}
            <StyledLinks>
                <DrawerButton url="/dashboard" icon={<HomeOutlined />}>
                    Dashboard
                </DrawerButton>
                <DrawerButton url="/surveys" icon={<FileTextOutlined />}>
                    Survey
                </DrawerButton>
                <DrawerButton url="/settings" icon={<SettingOutlined />}>
                    Settings
                </DrawerButton>
            </StyledLinks>
            <UserRestaurantDiv>
                <RestaurantButton />
                <ProfileButton />
            </UserRestaurantDiv>
        </div>
    </Drawer>
);

const UserRestaurantDiv = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const ProfileMenu = styled(Menu)`
    li {
        font-size: 18px;
        padding: 10px 12px;
    }
`;

const RestaurantButton = () => {
    const restaurants = useStoreState((state) => state.userModel.restaurants);
    const currentRestaurant = useStoreState((state) => state.userModel.currentRestaurant);
    const switchRestaurant = useSwitchRestaurant();

    if (!restaurants || restaurants.length === 0) return null;

    const menu = (
        <Menu>
            {restaurants.map((x) => (
                <Menu.Item key={x.id} disabled={x.id === currentRestaurant.id}>
                    <span onClick={() => switchRestaurant(x.id)}>{x.title}</span>
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <SmallerTextDiv>
            {restaurants.length === 1 && <DisabledAnchor>{currentRestaurant.title}</DisabledAnchor>}
            {restaurants.length > 1 && (
                <Dropdown placement="topCenter" overlay={menu}>
                    <a>
                        {currentRestaurant.title}
                        &nbsp;
                        <DownOutlinedIcon />
                    </a>
                </Dropdown>
            )}
        </SmallerTextDiv>
    );
};

const DownOutlinedIcon = styled(DownOutlined)`
    margin: 0 !important;
`;

const DisabledAnchor = styled.a`
    pointer-events: none;
`;

const SmallerTextDiv = styled.div`
    a {
        font-size: 110% !important;
    }
`;

const ProfileButton = () => {
    const firstname = useStoreState((state) => state.userModel.jwtContents?.firstName);

    return (
        <Dropdown
            overlay={
                <ProfileMenu>
                    <Menu.ItemGroup title={firstname}>
                        <Menu.Item key="AccountInfo">
                            <NavLink to="/account">Account</NavLink>
                        </Menu.Item>
                        <Menu.Item key="logout" onClick={resetStore}>
                            Logout
                        </Menu.Item>
                    </Menu.ItemGroup>
                </ProfileMenu>
            }
            placement="topLeft"
            align={{ offset: [16, -12] }}
        >
            <div data-testid="profile">
                <DrawerButton icon={<UserOutlinedIcon />}>
                    <></>
                </DrawerButton>
            </div>
        </Dropdown>
    );
};

const UserOutlinedIcon = styled(UserOutlined)`
    margin: 0 !important;
`;

const StyledLinks = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const DrawerButton = ({ url, icon, children }: { children; icon; url? }) => {
    const Component = url ? Link : ({ children, ...props }) => <a {...props}>{children}</a>;
    const props = url ? { to: url } : {};
    return (
        <Component {...props}>
            {icon}
            {children}
        </Component>
    );
};

const GrazeLogoName = () => (
    <GrazeLogoNameContainer>
        <LogoSvg />
        <GrazeName>Graze Analytics</GrazeName>
    </GrazeLogoNameContainer>
);

const GrazeLogoNameContainer = styled.header`
    display: flex;
    align-items: center;
    justify-content: center;
`;
