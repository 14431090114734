import { Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { SkeletonProps } from 'antd/lib/skeleton';

const AbsoluteDiv = styled.div`
    width: calc(100% - 48px);
    height: calc(100% - 48px);

    @media only screen and (max-width: 500px) {
        width: calc(100% - 24px);
        height: calc(100% - 24px);
    }

    overflow: hidden;
    position: absolute;

    > * {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }

    ~ * {
        opacity: 0;
    }

    h3 {
        margin-top: 0 !important;
    }

    li:last-child,
    ul,
    h3:last-child {
        margin-bottom: 0;
    }
`;

export const GrazeSkeleton = (props: SkeletonProps) => {
    return (
        <AbsoluteDiv>
            <Skeleton active {...props} />
        </AbsoluteDiv>
    );
};
