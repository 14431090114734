import { Action, action, computed, Computed, State, Thunk } from 'easy-peasy';
import { groupBy, uniq, uniqBy } from 'lodash-es';
import { ModelInterface } from './index';
import { blueberryClient } from '../helpers/blueberryClient';
import { NotifyNetworkError } from '../helpers/NotifyNetworkError';
import { debouncedThunk } from '../helpers/debouncedThunk';
import { isMenuItemReview, MenuItemReviewToSession } from './reviews';

export interface MenuItem {
    id: number;
    type: string;
    active: boolean;
    title: string; //name of the food
}

export interface MenuSection {
    title: string;
    menuItems: MenuItemWithReviews[];
}

export interface MenuItemWithReviews extends MenuItem {
    reviews: MenuItemReviewToSession[];
}

export interface MenuItemsModel {
    menuItems: Computed<MenuItemsModel, MenuItemWithReviews[]>;
    menuSections: Computed<MenuItemsModel, MenuSection[]>;
    _menuItems: MenuItem[];
    menuItemsAdded: boolean;
    _setMenuItems: Action<MenuItemsModel, MenuItem[]>;
    hydrateMenuItems: Thunk<MenuItemsModel, void, any, ModelInterface>;
}

const menuItemsModel: MenuItemsModel = {
    menuItemsAdded: false,
    _menuItems: [],
    menuItems: computed(
        [
            (menuItemsModel) => menuItemsModel._menuItems,
            (_, storeState: State<ModelInterface>) => storeState.reviewsModel.reviews,
        ],
        (menuItems, reviews) => {
            const menuItemReviews = reviews.filter(isMenuItemReview) as MenuItemReviewToSession[];
            const promptReviewsByTitle = groupBy(menuItemReviews, (x) => x.MenuItem.title);
            return menuItems.map((x) => ({ ...x, reviews: promptReviewsByTitle[x.title] ?? [] }));
        }
    ),
    menuSections: computed([(menuItemsModel) => menuItemsModel.menuItems], (menuItems) => {
        const uniqueMenuItems = uniqBy(menuItems, 'title');
        const menuCategories = uniq(uniqueMenuItems.map((x) => x.type));
        return menuCategories.map((x) => {
            const menuItemsForThisCategory = uniqueMenuItems.filter((y) => y.type === x);
            return { title: x, menuItems: menuItemsForThisCategory };
        });
    }),
    _setMenuItems: action((state, payload) => {
        state._menuItems = payload;
        state.menuItemsAdded = true;
    }),
    hydrateMenuItems: debouncedThunk(async (actions) => {
        try {
            const menuItems = await blueberryClient.getMenuItems();
            actions._setMenuItems(menuItems);
        } catch (e) {
            NotifyNetworkError('Error fetching menu items.');
            console.error('menu items hydration error:', e);
        }
    }),
};

export default menuItemsModel;
