import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { blueberryClient } from '../../helpers/blueberryClient';
import { GrowTextInput } from '../GrowTextInput';
import { useLoadSurveysCallback } from './EditSurveysContext';
import { useCurrentMenu } from './useCurrentMenu';
import { manipulateQuestionGallery } from './manipulateQuestionGallery';
import { useCurrentSurvey } from './EditSurvey';

const AddButton = ({ addOnServerFn, large, description }) => {
    const [adding, setAdding] = useState(false);
    const [title, setTitle] = useState('');
    const [addingOnServer, setAddingOnServer] = useState(false);
    const inputRef = useRef<any>();

    useEffect(() => {
        if (adding) inputRef.current?.focus();
    }, [adding]);

    const size = large ? 'large' : 'default';

    if (addingOnServer)
        return (
            <Button shape="round" loading disabled size={size}>
                {title}
            </Button>
        );

    if (!adding)
        return (
            <Button shape="round" onClick={() => setAdding(true)} type="dashed" size={size}>
                <PlusOutlined />
                {description}
            </Button>
        );

    const isNameEmpty = title.trim().length === 0;

    const startAddingOnServer = async () => {
        setAdding(false);

        if (isNameEmpty) return;

        setAddingOnServer(true);
        await addOnServerFn(title);
        setAddingOnServer(false);
        setTitle('');
    };

    return (
        <AddButtonInputContainer>
            <GrowTextInput
                placeholder={`${description} name`}
                innerRef={inputRef}
                onPressEnter={startAddingOnServer}
                onBlur={() => isNameEmpty && setAdding(false)}
                onChange={({ target }) => setTitle((target as any).value)}
                size={size}
            />
            <Button shape="circle" type="primary" size={size} onClick={startAddingOnServer}>
                <PlusOutlined />
            </Button>
        </AddButtonInputContainer>
    );
};

const AddButtonInputContainer = styled.div`
    display: inline-block;
    margin-bottom: 0 !important;
`;

export const AddMenuItemButton = ({ menuSectionId }) => {
    const createMenuItem = useLoadSurveysCallback(
        (title: string) => blueberryClient.createMenuItem({ title, menuSectionId }),
        [menuSectionId]
    );

    return <AddButton description="Menu Item" addOnServerFn={createMenuItem} large={false} />;
};

export const AddMenuSectionButton = () => {
    const { id } = useCurrentMenu();

    const createMenuSection = useLoadSurveysCallback(
        (title: string) => blueberryClient.createMenuSection({ title, menuId: id }),
        []
    );

    return <AddButton description="Menu Section" addOnServerFn={createMenuSection} large={true} />;
};

export const AddPromptButton = () => {
    const { id } = useCurrentSurvey();
    const executeAndLoadSurveys = useLoadSurveysCallback(async (fn: any) => await fn(), []);
    const showQuestionGallery = useCallback(
        () => manipulateQuestionGallery({ surveyId: id }, executeAndLoadSurveys),
        [executeAndLoadSurveys, id]
    );

    return (
        <Button shape="round" onClick={showQuestionGallery} type="dashed" size="large">
            <PlusOutlined />
            Question
        </Button>
    );
};
