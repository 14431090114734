import styled from 'styled-components';

export const GrazeLayout = styled.article`
    width: 87.5%;
    max-width: 800px;
    margin: 0 auto 2em;

    @media only screen and (max-width: 800px) {
        & {
            width: initial;
            max-width: 87.5%;
        }
    }
`;
