import React, { useState } from 'react';
import { RatingWithFollowUpByStarReviewData } from './ReviewInput';
import { FollowUp, RatingWithFollowUpByStarFlavorData } from '../../types/blueberryTypes';
import { ControlledCheckableButton } from '../PickFoodPage/CheckableButton';
import styled from 'styled-components';
import { useUpdateEffect } from 'react-use';

interface Props {
    value: RatingWithFollowUpByStarReviewData;
    onChange(data: RatingWithFollowUpByStarReviewData): void;
    starRating?: number;
    flavorData: RatingWithFollowUpByStarFlavorData;
}

export const FollowUpInput = ({ value, onChange, starRating, flavorData }: Props) => {
    const followUp: FollowUp | undefined = flavorData[starRating];
    const [responses, setResponses] = useState(value?.followUpResponses ?? []);
    useUpdateEffect(() => setResponses([]), [JSON.stringify(followUp?.reasons), followUp?.positive]);
    useUpdateEffect(() => onChange({ followUpResponses: [...responses] }), [responses]);
    if (!followUp) return null;

    const { question, reasons } = followUp;

    const isChecked = (x) => responses.indexOf(x) !== -1;

    return (
        <>
            <h2>{question}</h2>
            <FollowUpButtons>
                {reasons.map((x) => (
                    <ControlledCheckableButton
                        checkedColor={followUp.positive ? '#2ABD73' : '#E64654'}
                        checked={isChecked(x)}
                        onCheck={() => setResponses([...responses.filter((i) => i !== x), x])}
                        onUncheck={() => setResponses(responses.filter((i) => i !== x))}
                        key={x}
                    >
                        {x}
                    </ControlledCheckableButton>
                ))}
            </FollowUpButtons>
        </>
    );
};

const FollowUpButtons = styled.div`
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    margin: 4px 0 8px;
    > button {
        margin-right: 0.3em;
        margin-bottom: 0.3em;
    }
`;
