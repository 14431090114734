import { CleanupToken } from '../../customHooks/useAsyncEffect';
import { SurveyPrompt } from '../../helpers/blueberryClient';
import { ratingEmojiNames } from '@orchard/peach';

function getElementsWithTextContent(elem: Element, text, result = []) {
    if (elem.innerHTML === text) result.push(elem);
    Array.from(elem.children).forEach((x) => getElementsWithTextContent(x, text, result));
    return result;
}

function question(elem: HTMLElement, sleep: Sleep) {
    const find = (selector) => elem.querySelector(selector);
    const findByTextContent = (selector) => getElementsWithTextContent(elem, selector)[0];
    const findTextArea = () => find('textarea');
    return {
        async rate(x: 1 | 2 | 3 | 4 | 5) {
            const area = findTextArea();
            if (area) area.textContent = '';
            const attributeSelector = `[alt="${ratingEmojiNames[x - 1]}"]`;
            find(attributeSelector)?.click();
            await sleep(500);
        },
        async comment(text: string) {
            for (let i = 0; i < text.length; i++) {
                const area = findTextArea();
                if (area) area.textContent = text.substring(0, i + 1);
                await sleep(30);
            }
        },
        async reason(...reasons: string[]) {
            for (const reason of reasons) {
                findByTextContent(reason)?.click();
                await sleep(200);
            }
        },
    };
}

function makeSleep(ct: CleanupToken) {
    return function (ms: number) {
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                if (ct.isCleaningUp) reject(new CleanupException());
                else resolve();
            }, ms);
        });
    };
}

type Sleep = ReturnType<typeof makeSleep>;

class CleanupException extends Error {}

export async function animateQuestionCard(prompt: SurveyPrompt, elem: HTMLElement, ct: CleanupToken) {
    if (prompt.flavor !== 'ratingWithFollowUpByStar') return;
    const sleep = makeSleep(ct);
    const { rate, comment, reason } = question(elem, sleep);
    try {
        if (prompt.title.includes('service')) {
            while (true) {
                await rate(5);
                await reason('Friendliness', 'Attentive', 'Fast');
                await comment('Alex was on it tonight! They were very attentive and personable.');
                await sleep(2000);
                await rate(3);
                await reason('Slow');
                await comment('Susie was nice, but our food came out a bit slow!');
                await sleep(2000);
            }
        } else if (prompt.title.includes('atmosphere')) {
            while (true) {
                await rate(5);
                await reason('Decor', 'Ambiance', 'Cleanliness');
                await comment(`Very enjoyable and unique atmosphere. We'll be back!`);
                await sleep(2000);
                await rate(2);
                await reason('Dirty', 'Lighting');
                await comment(`The lighting was so low I couldn't read the menu. Also, my table was sticky.`);
                await sleep(2000);
            }
        }
    } catch (e) {
        if (e instanceof CleanupException) return;
        throw e;
    }
}
