import styled from 'styled-components';
import { Link } from 'react-router-dom';
import React, { HTMLAttributes } from 'react';
import { getSubjectRelativeUrl, isPrompt, Subject } from '../model/subjects';
import { useStoreState } from '../model';
import { Prompt } from '../model/prompts';
import { Tooltip } from 'antd';
import { CommentsCount } from './CommentsCount';
import { countComments } from '../model/reviews';
import { MenuItem } from '../model/menu';

const StyledLink = styled(Link)`
    font-weight: 500;
    color: rgba(0, 0, 0, 0.65) !important;
    &:active,
    &:hover {
        text-decoration: underline;
    }
`;

interface SubjectProps {
    subject: Subject;
}

export const SubjectTitle = ({ subject }: SubjectProps) =>
    isPrompt(subject) ? <PromptTitle prompt={subject} /> : <MenuItemTitle menuItem={subject} />;

const MenuItemTitle = ({ menuItem }: { menuItem: MenuItem }) => {
    const menuItems = useStoreState((x) => x.menuItemsModel.menuItems);
    const menuItemsExt = menuItems.find((x) => x.id === menuItem.id);
    if (!menuItemsExt) return <SubjectLink subject={menuItem} />;
    return <SubjectLinkWithTooltip subject={menuItem} commentsCount={countComments(menuItemsExt.reviews)} />;
};

const PromptTitle = ({ prompt }: { prompt: Prompt }) => {
    const prompts = useStoreState((x) => x.promptsModel.prompts);
    const promptExt = prompts.find((x) => x.id === prompt.id);
    if (!promptExt || prompt.flavor === 'multipleChoice') return <SubjectLink subject={prompt} />;
    return <SubjectLinkWithTooltip subject={prompt} commentsCount={countComments(promptExt.reviews)} />;
};

const SubjectLink = ({ subject, ...rest }: SubjectProps & HTMLAttributes<{}>) => (
    <StyledLink to={getSubjectRelativeUrl(subject)} {...rest}>
        {subject.title}
    </StyledLink>
);

const SubjectLinkWithTooltip = ({ subject, commentsCount }: SubjectProps & { commentsCount: number }) => (
    <Tooltip title={<CommentsCount commentsCount={commentsCount} />} align={{ offset: [0, 5] }} placement="topLeft">
        <SubjectLink subject={subject} />
    </Tooltip>
);
