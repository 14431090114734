import { useStoreActions, useStoreState } from '../model';
import { useThunk } from './useThunk';

export const useHydratePrompts = () => {
    const hydratePrompts = useStoreActions((actions) => actions.promptsModel.hydrateAllPrompts);
    const promptsAdded = useStoreState((x) => x.promptsModel.promptsAdded);

    useThunk(hydratePrompts);

    return { promptsHydrated: promptsAdded };
};
