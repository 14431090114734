import React from 'react';
import { VerticalCenteredGrazeSpinner } from './brand/VerticalCenteredGrazeSpinner';
import { NotFound } from './NotFound';
import { useKetchupMustardSea } from '../customHooks/useKetchupMustardSea';
import { useParamsId } from '../customHooks/useParamsId';
import { GrazeLayout } from './brand/GrazeLayout';
import { CommentsList } from './CommentsList';
import { useHydratePrompts } from '../customHooks/useHydratePrompts';
import { useHydrateMenuItems } from '../customHooks/useHydrateMenuItems';
import { useHydrateSessions } from '../customHooks/useHydrateSessions';
import { SubjectCommentCard } from './card/SubjectCommentCard';
import { HeadingWithOverviewTimeRangeSelect } from './home/HeadingWithOverviewTimeRangeSelect';
import { countComments } from '../model/reviews';
import styled from 'styled-components';
import { useReviewsInOverviewTimeRange } from '../customHooks/useReviewsInOverviewTimeRange';
import { Button } from 'antd';
import { useOverviewTimeRange } from '../customHooks/useOverviewTimeRange';

export const StarRouter = () => {
    const rating = useParamsId();
    useKetchupMustardSea();

    if (rating < 1 || rating > 5) return <NotFound />;

    return <StarPage rating={rating} />;
};

const StyledButton = styled(Button)`
    display: block;
    margin: 2em auto 0;
`;

const StarPage = ({ rating }: { rating: number }) => {
    const { promptsHydrated } = useHydratePrompts();
    const { menuItemsHydrated } = useHydrateMenuItems();
    const { sessionsHydrated } = useHydrateSessions();
    const [range, setTimeRange] = useOverviewTimeRange();
    const reviews = useReviewsInOverviewTimeRange();

    const loaded = promptsHydrated && menuItemsHydrated && sessionsHydrated;
    if (!loaded) return <VerticalCenteredGrazeSpinner />;

    const myReviews = reviews.filter((x) => x.starRating === rating);
    const commentsCount = countComments(myReviews);

    const showAllTimeButton = range === '30-days' && commentsCount < 25;
    return (
        <GrazeLayout>
            <HeadingWithOverviewTimeRangeSelect>{rating}-Star Reviews</HeadingWithOverviewTimeRangeSelect>
            <StarPageStats ratingsCount={myReviews.length} commentsCount={commentsCount} />
            {commentsCount > 0 && <CommentsList reviews={myReviews} CommentComponent={SubjectCommentCard} />}
            {showAllTimeButton && (
                <StyledButton type="primary" size="large" shape="round" onClick={() => setTimeRange('all-time')}>
                    Show All-Time
                </StyledButton>
            )}
        </GrazeLayout>
    );
};

const H2 = styled.h2`
    margin: 10px 0;
`;

const StarPageStats = ({ ratingsCount, commentsCount }) => (
    <H2>
        {ratingsCount} {ratingsCount === 1 ? 'rating' : 'ratings'} • {commentsCount}{' '}
        {commentsCount === 1 ? 'comment' : 'comments'}
    </H2>
);
