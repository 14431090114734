import React from 'react';
import { useHydrateSessions } from '../customHooks/useHydrateSessions';
import { useStoreState } from '../model';
import { useHydratePrompts } from '../customHooks/useHydratePrompts';
import { SubjectRouter } from './SubjectRouter';

export const PromptRouter = () => {
    const { sessionsHydrated } = useHydrateSessions();
    const { promptsHydrated } = useHydratePrompts();
    const prompts = useStoreState((x) => x.promptsModel.prompts);
    return <SubjectRouter loaded={sessionsHydrated && promptsHydrated} subjects={prompts} />;
};
