import React from 'react';
import { useHydrateSessions } from '../customHooks/useHydrateSessions';
import { useHydrateMenuItems } from '../customHooks/useHydrateMenuItems';
import { useStoreState } from '../model';
import { SubjectRouter } from './SubjectRouter';

export const MenuItemRouter = () => {
    const { sessionsHydrated } = useHydrateSessions();
    const { menuItemsHydrated } = useHydrateMenuItems();
    const menuItems = useStoreState((x) => x.menuItemsModel.menuItems);
    return <SubjectRouter loaded={sessionsHydrated && menuItemsHydrated} subjects={menuItems} />;
};
