import styled from 'styled-components';
import { Select } from 'antd';
import React, { useRef } from 'react';
import { SelectProps } from 'antd/es/select';

const StyledSelect = styled(Select)`
    margin-left: 0.875em;
    position: relative;
    top: 0.3em;

    & .ant-select-selection-selected-value {
        margin-right: 0.5em;
    }
`;

export const GrazeSelect = ({ children, onChange, ...rest }: SelectProps & { children }) => {
    const selectRef = useRef<Select>(null);

    return (
        <StyledSelect
            {...rest}
            onChange={function (...args) {
                if (onChange) {
                    // @ts-ignore
                    onChange(...args);
                }
                selectRef.current.blur();
            }}
            ref={selectRef}
        >
            {children}
        </StyledSelect>
    );
};
