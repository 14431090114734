import { GrazeCard } from '../brand/GrazeCard';
import React from 'react';
import styled, { css } from 'styled-components';
import { AverageRating } from '../AverageRating';
import { Link } from 'react-router-dom';
import { GrazeSkeleton } from '../brand/GrazeSkeleton';
import { Review } from '../../model/reviews';
import { FilledBar } from '../FilledBar';
import { PercentText } from '../PercentText';

type OnClick = (() => void) | string | undefined;

export interface RatingsBreakdownProps {
    reviews: Review[];
    loading?: boolean;
    onClicks?: {
        1?: OnClick;
        2?: OnClick;
        3?: OnClick;
        4?: OnClick;
        5?: OnClick;
    };
    className?: string;
}

interface BarProps {
    prefix: string;
    unit: number;
    onClick: OnClick;
}

const getRatingsCount = (reviews: Review[]) => {
    const ratingsCount = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
    };

    reviews.filter((x) => x.starRating).forEach((x) => ratingsCount[x.starRating]++);

    return ratingsCount;
};

const BarLink = styled(Link)`
    width: 100%;
    display: flex;
    align-items: center;
    & > * {
        margin: 0 0.25em;
    }
    color: rgba(0, 0, 0, 0.65) !important;
    & > div:nth-child(1) {
        text-align: right;
    }
    & > div:nth-child(3) {
        width: 2em;
    }
    & > div:nth-child(1) {
        width: 3.3em;
    }
    &:hover,
    &:focus {
        text-decoration: underline;
    }
    &:hover > div:nth-child(2),
    &:focus > div:nth-child(2) {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.65);
    }
    ${({ onClick, to }) =>
        !(onClick || to) &&
        css`
            pointer-events: none;
        `}
`;

const Bar = ({ prefix, unit, onClick }: BarProps) => {
    return (
        <BarLink
            onClick={typeof onClick === 'function' ? onClick : undefined}
            to={typeof onClick === 'string' && onClick}
        >
            <div>{prefix}</div>
            <FilledBar unit={unit} />
            <PercentText unit={unit} />
        </BarLink>
    );
};

const RatingsBreakdownCard = styled(GrazeCard)`
    position: relative;
    display: flex;
    @media only screen and (max-width: 500px) {
        flex-direction: column;
    }
`;

const RatingsOverviewContainer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: 500px) {
        padding-right: 24px;
    }
    @media only screen and (max-width: 500px) {
        padding-bottom: 4px;
    }
    & > *:nth-child(1) {
        font-size: 64px;
        line-height: 64px;
        @media only screen and (max-width: 500px) {
            font-size: 48px;
            line-height: 48px;
        }
    }
    & > *:nth-child(2) {
        font-size: 28px;
        @media only screen and (max-width: 500px) {
            font-size: 21px;
        }
    }
`;

const RatingsBreakdownContainer = styled.div`
    font-size: 14px;
    flex-grow: 3;
    display: flex;
    flex-direction: column;
`;

export const RatingsBreakdown = ({ reviews, loading, onClicks, className }: RatingsBreakdownProps) => {
    onClicks = onClicks ?? {};
    const count = getRatingsCount(reviews);
    const starRatingsCount = count[1] + count[2] + count[3] + count[4] + count[5];
    const averageRating = (count[1] + count[2] * 2 + count[3] * 3 + count[4] * 4 + count[5] * 5) / starRatingsCount;

    return (
        <RatingsBreakdownCard className={className}>
            {loading && <GrazeSkeleton paragraph={{ rows: 2 }} />}
            <RatingsOverviewContainer>
                <AverageRating value={averageRating} />
                <div>{starRatingsCount} ratings</div>
            </RatingsOverviewContainer>
            <RatingsBreakdownContainer>
                <Bar prefix="5-star" unit={count[5] / starRatingsCount} onClick={onClicks[5]} />
                <Bar prefix="4-star" unit={count[4] / starRatingsCount} onClick={onClicks[4]} />
                <Bar prefix="3-star" unit={count[3] / starRatingsCount} onClick={onClicks[3]} />
                <Bar prefix="2-star" unit={count[2] / starRatingsCount} onClick={onClicks[2]} />
                <Bar prefix="1-star" unit={count[1] / starRatingsCount} onClick={onClicks[1]} />
            </RatingsBreakdownContainer>
        </RatingsBreakdownCard>
    );
};
