import styled from 'styled-components';
import { Select } from 'antd';
import React from 'react';
import { useControlsState } from '../customHooks/useControlsState';
import { GrazeSelect } from './brand/GrazeSelect';

const { Option } = Select;

const StyledSelect = styled(GrazeSelect)`
    width: 14em;
`;

type GuestSortOption =
    | 'most-review-sessions-first'
    | 'latest-review-first'
    | 'highest-average-rating'
    | 'lowest-average-rating';

export const useGuestSortOption = () =>
    useControlsState<GuestSortOption>('guest-sort-select', 'most-review-sessions-first');

export const GuestSortSelect = () => {
    const [guestSortOption, setGuestSortOption] = useGuestSortOption();

    return (
        <StyledSelect value={guestSortOption} onChange={(x: GuestSortOption) => setGuestSortOption(x)}>
            <Option value="most-review-sessions-first">Most reviews</Option>
            <Option value="latest-review-first">Latest</Option>
            <Option value="highest-average-rating">Highest average rating</Option>
            <Option value="lowest-average-rating">Lowest average rating</Option>
        </StyledSelect>
    );
};
