import styled from 'styled-components';

export const HeaderWithHiddenButtons = styled.header`
    * {
        display: inline;
    }
    button {
        margin-left: 8px;
        margin-right: 0 !important;
        visibility: hidden;
    }
    :hover button {
        visibility: visible;
    }
`;
