/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, Input, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useHistory } from 'react-router-dom';

import logo from '../svg/logo.svg';
import '../css/Login.css';
import getApiUrl from '../helpers/getApiUrl';
import { useStoreActions } from '../model';
import { useQuery } from '../customHooks/useQuery';

const Login: React.SFC<FormComponentProps> = (props) => {
    const setJWTContents = useStoreActions((actions) => actions.userModel.setJwtContents);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const nextPath = useQuery().get('onlogin') ?? '/dashboard';

    const handleSubmit = async (e): Promise<void> => {
        e.preventDefault();

        props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    setLoading(true);
                    const response = await axios.get(
                        `${getApiUrl()}/users?username=${values.username}&password=${values.password}`
                    );
                    const token = response.data.token as string;
                    setJWTContents(token);
                    history.push(nextPath);
                } catch (err) {
                    props.form.setFields({
                        username: { value: values.username, errors: [new Error('Incorrect Username or Password')] },
                        password: { value: '', errors: [new Error('Incorrect Username or Password')] },
                    });
                } finally {
                    setLoading(false);
                }
            }
        });
    };
    const { getFieldDecorator } = props.form;

    return (
        <>
            <div className="App">
                <header className="App-header">
                    <div className="loginHeader">
                        <div className="cell">
                            <img src={logo} alt="Graze Analytics Logo" className="logo" />
                        </div>
                        <div className="cell">
                            <h2 className="title">Graze Analytics</h2>
                        </div>
                    </div>
                    <Form onSubmit={handleSubmit} id="login-form">
                        <Form.Item className="text-field" id="username-field">
                            {getFieldDecorator('username', {
                                rules: [{ required: true, message: 'Please input your Username!' }],
                            })(
                                <Input
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Username"
                                    name="username"
                                />
                            )}
                        </Form.Item>
                        <Form.Item className="text-field">
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'Please input your Password!' }],
                            })(
                                <Input
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                />
                            )}
                        </Form.Item>
                        <Form.Item>
                            <div className="bottom-form">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button"
                                    loading={loading}
                                >
                                    Log in
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </header>
            </div>
        </>
    );
};

export default Form.create<FormComponentProps>()(Login);
