import { useStoreActions, useStoreState } from '../model';
import { useThunk } from './useThunk';

export const useHydrateSessions = () => {
    const hydrateSessions = useStoreActions((actions) => actions.sessionModel.hydrateSessions);
    const sessionsUpdated = useStoreState((x) => x.sessionModel.sessionsUpdated);

    useThunk(hydrateSessions);

    return { sessionsHydrated: sessionsUpdated };
};
