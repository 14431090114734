function getEnvironmentType() {
    const currentPage = window.location.href;

    if (currentPage.includes('devapp.grazeanalytics.com')) {
        return 'dev';
    }
    if (currentPage.includes('app.grazeanalytics.com')) {
        return 'prod';
    }
    return 'local';
}

export const environment = getEnvironmentType();
