import { useStoreActions, useStoreState } from '../model';
import { useThunk } from './useThunk';

export const useHydrateAll = () => {
    const currentRestaurantId = useStoreState((state) => state.userModel.currentRestaurant.id);
    const hydrateSessions = useStoreActions((actions) => actions.sessionModel.hydrateSessions);
    const hydrateMenuItems = useStoreActions((actions) => actions.menuItemsModel.hydrateMenuItems);
    const hydratePrompts = useStoreActions((actions) => actions.promptsModel.hydrateAllPrompts);
    const hydrateSubscription = useStoreActions((actions) => actions.subscription.loadSubscription);
    const hydrateRestaurants = useStoreActions((actions) => actions.userModel.hydrateRestaurants);

    useThunk(hydrateSessions, [currentRestaurantId]);
    useThunk(hydrateMenuItems, [currentRestaurantId]);
    useThunk(hydratePrompts, [currentRestaurantId]);
    useThunk(hydrateSubscription, [currentRestaurantId]);
    useThunk(hydrateRestaurants, [currentRestaurantId]);
};
