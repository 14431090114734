import { NotFound } from '../NotFound';
import React from 'react';
import { useCurrentSurvey } from './EditSurvey';
import { useCurrentSurveyUrl } from './CurrentSurveyUrl';
import { GrazeLayout } from '../brand/GrazeLayout';
import { PhoneIframe } from '../brand/PhoneIframe';

export const PreviewSurvey = () => {
    const { found } = useCurrentSurvey();

    if (!found) return <NotFound />;

    return <PreviewSurveyImpl />;
};

const PreviewSurveyImpl = () => {
    const { name } = useCurrentSurvey();
    const url = useCurrentSurveyUrl();

    return (
        <GrazeLayout>
            <h1>{name}</h1>
            <PhoneIframe src={url} />
        </GrazeLayout>
    );
};
