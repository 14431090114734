import React from 'react';
import { ReviewRating } from './RatingInput';
import { ReviewComment } from './CommentInput';
import { Flavor, FlavorUnion } from '../../types/blueberryTypes';
import { RatingCommentInput } from './RatingCommentInput';
import { MultipleChoiceInput } from './MultipleChoiceInput';

interface Review {
    rating: ReviewRating;
    comment: ReviewComment;
    data: ReviewData;
}

export type RatingWithFollowUpByStarReviewData = { followUpResponses: string[] };

export type MultipleChoiceReviewData = { choice: string | null };

export type ReviewData = null | RatingWithFollowUpByStarReviewData | MultipleChoiceReviewData;

export interface ReviewInputPropsBase<TData = ReviewData> {
    onChange(review: Review): void;
    defaultValue?: Omit<Review, 'data'> & { data: TData };
}

export interface ReviewInputProps extends ReviewInputPropsBase {
    flavor: FlavorUnion;
    placeholder?: string;
}

export const ReviewInput = (props: ReviewInputProps) => {
    switch (props.flavor.flavor) {
        case Flavor.MultipleChoice:
            return <MultipleChoiceInput {...(props as any)} />;
    }

    return <RatingCommentInput {...(props as any)} />;
};
