import { createTypedHooks } from 'easy-peasy';
import menuItemsModel from './menu';
import userModel from './user';
import reviewsModel from './reviews';
import sessionModel from './sessions';
import promptsModel from './prompts';
import { guestsModel } from './guests';
import { controls } from './controls';
import { subscription } from './subscription';

export const models = {
    menuItemsModel,
    userModel,
    reviewsModel,
    sessionModel,
    promptsModel,
    guestsModel,
    controls,
    subscription,
};

export type ModelInterface = typeof models;

export const { useStore, useStoreState, useStoreActions, useStoreDispatch } = createTypedHooks<ModelInterface>();

export default models;
